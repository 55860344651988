import "driver.js/dist/driver.css";
import { driver } from "driver.js";
import { tioVersion } from "./version";
import { loadShownTourItems, saveShownTourItems } from "./storage";
import { uiLanguage } from "./i18n";
import { addModalTour_de, editModalTour_de, mainTour_de, menuTour_de, miniSyncTour_de, todoDetailsTour_de } from "./tour.de";
import { addModalTour_en, editModalTour_en, mainTour_en, menuTour_en, miniSyncTour_en, todoDetailsTour_en } from "./tour.en";

const shown = loadShownTourItems();
let dryRun = false;
let currentDriverObj = false;

export function isTourOpen() {
  return !!currentDriverObj;
}

export function markAllShown() {
  dryRun = true;
  try {
    mainTour();
    addModalTour();
    editModalTour();
    menuTour();
    miniSyncTour();
    todoDetailsTour();
  } finally {
    dryRun = false;
  }
}

export function resetShown() {
  shown.length = 0;
  saveShownTourItems(shown);
}

export function mainTour() {
  if (uiLanguage === 'de') {
    highlight(mainTour_de());
  } else {
    highlight(mainTour_en());
  }
}

export function miniSyncTour() {
  if (uiLanguage === 'de') {
    highlight(miniSyncTour_de());
  } else {
    highlight(miniSyncTour_en());
  }
}

export function todoDetailsTour() {
  if (uiLanguage === 'de') {
    highlight(todoDetailsTour_de());
  } else {
    highlight(todoDetailsTour_en());
  }
}

export function addModalTour() {
  if (uiLanguage === 'de') {
    highlight(addModalTour_de());
  } else {
    highlight(addModalTour_en());
  }
}

export function editModalTour() {
  if (uiLanguage === 'de') {
    highlight(editModalTour_de());
  } else {
    highlight(editModalTour_en());
  }
}

export function menuTour() {
  if (uiLanguage === 'de') {
    highlight(menuTour_de());
  } else {
    highlight(menuTour_en());
  }
}

function highlight(list) {
  if (dryRun) {
    for (let i = 0; i < list.length; i++) {
      const elem = list[i];
      const selector = elem[0];
      shown.push(selector);
    }
    saveShownTourItems(shown);
    return;
  }
  if (isTourOpen()) {
    return;
  }
  const steps = [];
  for (let i = 0; i < list.length; i++) {
    const elem = list[i];
    const selector = elem[0];
    if (selector && selector.length > 0) {
      const element = document.querySelector(selector);
      if (element && !shown.includes(selector)) {
        shown.push(selector);
        let description = elem[1];
        if (elem.length > 2) {
          if (elem[2] === tioVersion) {
            description = '<span style="background-color: yellow; font-weight: bold;">NEU:</span> ' + description;
          }
        }
        steps.push({
          element: element,
          popover: {
            description: description
          }
        });
      }
    }
  }
  
  if (steps.length > 0) {
    const buttons = steps.length === 1 ? ['close'] : ['previous', 'next', 'close'];
    const driverObj = driver({
      animate: false,
      popoverClass: "driverjs-theme",
      disableActiveInteraction: true,
      stagePadding: 6,
      nextBtnText: 'Weiter',
      prevBtnText: 'Zurück',
      doneBtnText: 'Schließen',
      showButtons: buttons,
      onDestroyed: () => {
        console.log("Tour closed.");
        currentDriverObj = false;
      }
    });
    currentDriverObj = driverObj;
    driverObj.setSteps(steps);
    driverObj.drive();
    saveShownTourItems(shown);
  }
}

//import snarkdown from "snarkdown";
import MarkdownIt from 'markdown-it';
import { full as emoji } from 'markdown-it-emoji';
import { durationToMinutes, endOfMonth, fmtDate, fmtDay, fmtTime, infoDateDay, isDue, isDueSoon, isOver, startOfMonth, titleDateDay, titleDateMonth, titleDateWeek } from './dates';
import { typeOrder } from './bucket';
import { isEnabled } from './features';
import { escapeHtml } from 'markdown-it/lib/common/utils';
import { isMobile, isTablet } from './browser';
import { formatDuration, formatDurationTitle, todoMap } from './items';
import { translate } from './i18n';
import { getTagColor, getTextColorForBg, isAutoTag, removeAutoPrefix, tagMarker } from './tags';
const md = new MarkdownIt({
  html: false,
  breaks: true,
  linkify: true,
}).use(emoji);
export const iconRepeat = '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16"><path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/><path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/></svg>';
export const iconExRepeat = '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16"><path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/><path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>  <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/></svg>';
export const iconNote = '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-sticky" viewBox="0 0 16 16"><path d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1h-11zM2 2.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V8H9.5A1.5 1.5 0 0 0 8 9.5V14H2.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V9.5a.5.5 0 0 1 .5-.5h4.293L9 13.793z"/></svg>';
export const iconImported = '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/></svg>';
export const iconInstance = '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-arrow-down-right-circle" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.854 5.146a.5.5 0 1 0-.708.708L9.243 9.95H6.475a.5.5 0 1 0 0 1h3.975a.5.5 0 0 0 .5-.5V6.475a.5.5 0 1 0-1 0v2.768L5.854 5.146z"/></svg>';
export const iconSingle = '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor"></svg>';
export const iconClone = '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16"><path stroke="grey" strokeWidth="3" fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/><path stroke="grey" strokeWidth="3" fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/></svg>';
export const iconStopwatch = '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-stopwatch" viewBox="0 0 16 16"><path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z"/><path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z"/></svg>';
export const iconExclamationSquare = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/><path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/></svg>';
export const iconCircleFill = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-circle-fill" viewBox="0 -1 16 18"><circle cx="8" cy="10" r="7"/></svg>';
export const NO_SHORTCUT = null;

let implicitAttentionCounter = 0;
export function titleAddAttention(implicitRemove = false) {
  if (implicitRemove) {
    implicitAttentionCounter++;
  }
  if (document.title.charAt(0) === '!') {
    document.title = '!' + document.title;
  } else {
    document.title = '! ' + document.title; 
  }
}

export function titleRemoveImplicitAttention() {
  while (implicitAttentionCounter > 0) {
    implicitAttentionCounter--;
    titleRemoveAttention();
  }
}

export function titleRemoveAttention() {
  if (document.title.charAt(0) === '!') {
    document.title = document.title.substring(1).trim();
  }
}

export function setTitleKeepAttention(newTitle) {
  document.title = startExclMarks(document.title) + newTitle + ' | TIO Planner';
}

function startExclMarks(string) {
  let marks = '';
  while (string.length > 0 && string.charAt(0) === '!') {
    string = string.substring(1);
    marks += '!';
  }
  if (marks.length > 0) {
    marks += ' ';
  }
  return marks;
}

export function loadScript(src, id = false, callback = false) {
  if (id && document.getElementById(id)) {
    console.log(src + ' already loaded.');
    if (callback) {callback();}
    return;
  }
  const script = document.createElement('script');
  script.src = src;
  if (id) {
    script.id = id;
  }
  if (callback) {
    script.onload = () => {
      console.log(src + ' loaded.');
      if (callback) {callback();}
    };
  }
  document.body.appendChild(script);
  return script;
}

export function appendNavItemOpt(to, id, title, shortcut, action) {
  if (isEnabled(id)) {
    return appendNavItem(to, id, title, shortcut, action);
  }
}

export function appendNavItem(to, id, title, shortcut, action) {
  const li = document.createElement('li');
  li.classList.add('nav-item');
  let shortcutHtml = '';
  if (shortcut && !isTablet()) {
    shortcutHtml = ` <small class="d-none d-sm-inline">(${shortcut})</small>`;
  }
  let aria = title;
  const tag = aria.indexOf('<');
  if (tag > 0) {
    aria = aria.substring(0, tag);
  }
  li.innerHTML = `<button type="button" class="btn btn-lg btn-link" aria-label="${aria}" id="${id}">
    <span class="title">${title}</span>${shortcutHtml}
  </button>`;
  to.appendChild(li);
  if (action) {
    li.addEventListener('click', action);
  }
  return li;
}

export function spinner() {
  const span = document.createElement('span');
  span.classList.add('spinner-border', 'spinner-border-sm');
  span.role = 'status';
  span.ariaHidden = 'true';
  return span;
}

export function disable(elem) {
  elem.classList.add('disabled');
  elem.disabled = true;
}

export function enable(elem) {
  elem.classList.remove('disabled');
  elem.disabled = false;
}

export function setSelectOptions(wrapper, options, selected) {
  const select = wrapper.querySelector('select');
  const spinner = wrapper.querySelector('.spinner-border');
  options.forEach(name => {
    const opt = document.createElement('option');
    opt.value = name;
    opt.innerText = name;
    if (selected && selected === name) {
      opt.selected = true;
    }
    select.appendChild(opt);
  });
  spinner.classList.add('d-none');
  enable(select);
}

export function appendNavHeading(to, title) {
  const li = document.createElement('li');
  li.classList.add('nav-item');
  li.innerHTML = `<h5 class="title">${title}</h5>`;
  to.appendChild(li);
  return li;
}

export function appendNavSep(to) {
  const li = document.createElement('li');
  li.classList.add('nav-item');
  li.innerHTML = '<hr class="w-90 mt-0 mb-0">';
  to.appendChild(li);
}

export function appendNavItemConnect(to, id, modal) {
  const li = document.createElement('li');
  li.classList.add('nav-item');
  li.id = `${id}-authentication`;
  let modalHtml = '';
  if (modal) {
    modalHtml = ` data-toggle="modal" data-target="#${id}Modal"`;
  }
  li.innerHTML = `<button type="button" class="btn btn-lg btn-link btn-login text-right" id="${id}-login-button"
  ${modalHtml}>
    <span class="title">Connect</span>
  </button>
  <button type="button" class="btn btn-lg btn-link btn-logout text-right text-nowrap" id="${id}-logout-button">
    <span class="title">Disconnect</span> <small class="username"></small>
  </button>`;
  to.appendChild(li);
  return li;
}

export function createItemDateTitle(item, bucketSubtitle) {
  let title;
  if (item.due.type === 'month') {
    title = titleDateMonth(item.due.date);
  } else if (item.due.type === 'week') {
    title = titleDateWeek(item.due.date);
  } else {
    title = fmtDay(item.due.date) + ', ' + titleDateDay(item.due.date, 1);
  }
  if (title === bucketSubtitle) {
    return null;
  }
  const div = document.createElement('small');
  div.classList.add('in-bucket-day');
  if (item.done) {
    div.classList.add('todo-done');
  }
  div.innerHTML = title;
  div.dataset.date = fmtDate(item.due.date);
  return div;
}

export function itemPopoverTitle(item) {
  let add = '';
  if (item.instanceOf) {
    add += iconRepeat;
  }
  if (item.wasInstanceOf) {
    add += iconExRepeat;
  }
  if (item.original) {
    add += iconImported;
  }

  return `<span data-id="${item.id}">${markdown(item.title)}<span class="float-right ml-3">${add}</span></span>`;
}

export function itemPopoverBody(item) {
  let notes = '';
  if (item.notes) {
    notes = markdownParagraph(item.notes);
  }
  if (item.due) {
    let due = '';
    if (item.due.type === 'month') {
      due = titleDateMonth(item.due.date);
    } else if (item.due.type === 'week') {
      due = titleDateWeek(item.due.date);
    } else {
      due = infoDateDay(item.due.date);
      if (item.due.time) {
        due += ', ' + item.due.time + 'h';
      }
    }
    notes += `<div><small><span class="due-title">Due</span>: ${due}</small></div>`;
  }
  if (item.tags) {
    let tagString = '';
    item.tags.forEach(tag => {
      if (tagString.length > 0) {
        tagString += ' ';
      }
      let bgColor;
      let textColor;
      if (isAutoTag(tag)) {
        const tagName = removeAutoPrefix(tag);
        bgColor = getTagColor(tagName);
        textColor = getTextColorForBg(bgColor);  
        tag = `<span title="automatically added" class="text-muted">${escapeHtml(tagName)}*</span>`;
      } else {
        bgColor = getTagColor(tag);
        textColor = getTextColorForBg(bgColor);  
        tag = escapeHtml(tag);
      }
      const button = `<button type="button" class="btn btn-small btn-light px-2 py-1" style="cursor: default; outline: none; border-radius: 0.6rem; vertical-align: baseline; color: ${textColor}; background-color: ${bgColor}; border-color: ${bgColor};">${tag}</button>`;
      tagString += button;
    });
    if (tagString.length > 0) {
      notes += `<div><small><span class="tags-title">Tags</span>: ${tagString}</small></div>`;
    }
  }
  if (item.duration) {
    notes += `<div><small><span class="duration-title">Duration</span>: ${item.duration}</small></div>`;
  }
  if (item.created) {
    notes += `<div><small><span class="created-title">Created</span>: ${infoDateDay(item.created)}</small></div>`;
  }
  if (item.done) {
    notes += `<div><small><span class="done-title">Done</span>: ${infoDateDay(item.done)}</small></div>`;
  }
  if (item.imported) {
    if (item.imported.kind === 'calendar') {
      if (item.imported.type === 'google') {
        notes += `<div><small><span class="imported-title">Imported from</span>: Google Calendar</small></div>`;
      } else if (item.imported.type === 'o365') {
        notes += `<div><small><span class="imported-title">Imported from</span>: O365 Calendar</small></div>`;
      }
    }
  }
  if (item.instanceOf) {
    const original = todoMap.get(item.instanceOf);
    notes += property('Repeat', repeatTitle(original)) + 
      property('Original Created', infoDateDay(original.created));
  }
  if (item.wasInstanceOf) {
    const original = todoMap.get(item.wasInstanceOf);
    notes += property('Repeat Was', repeatTitle(original)) + 
      property('Original Created', infoDateDay(original.created));
  }
  if (isEnabled('debug-properties')) {
    notes += property('Priority', item.priority) +
      property('Previous Priorites', prioString(item.prevprio)) + 
      property('Due-Type', item.due ? item.due.type : '-') +
      property('ID', item.id) +
      property('Series ID', item.instanceOf) +
      property('Was Series ID', item.wasInstanceOf) + 
      property('New', item.new)
      ;

  }
  return notes;
}

function prioString(prios) {
  if (!prios || prios.length === 0) {
    return null;
  }
  let string = '';
  prios.forEach((prio) => {
    if (string.length > 0) {
      string += ' - ';
    }
    string += `${prio.prio}/${prio.type}/${fmtDate(prio.time)}/${fmtTime(prio.time)}`;
  });
  return string;
}

function repeatTitle(item) {
  let repeat = '';
  if (item && item.repeat) {
    if (item.repeat.every === 1) {
      repeat = translate('every-' + item.repeat.scope);
    } else {
      repeat = `${translate('every')} ${item.repeat.every} ${translate(item.repeat.scope + 's')}`;
    }
    if (item.repeat.scope === 'week') {
      repeat += ', ' + fmtDay(item.due.date);
    } else if (item.repeat.scope === 'month') {
      repeat += ', ' + new Date(item.due.date).getDate();
    }
  }
  return repeat;
}

function property(title, content, cls = '') {
  if (typeof(content) === 'undefined' || content === null || content === '') {
    return '';
  }
  if (cls === '') {
    cls = title.toLowerCase().replace(/ /g, '-') + '-title';
  }
  const html = `<div><small><span class="${cls}">${title}</span>: ${content}</small></div>`;
  return html;
}

export function dueTimeStatus(item) {
  let over = '';
  if (isOver(item.due.date, item.due.time, item.duration)) {
    over = 'text-danger';
  } else if (isDue(item.due.date, item.due.time)) {
    over = 'text-warning';
  } else if (isDueSoon(item.due.date, item.due.time)) {
    over = 'text-primary';
  }
  return over;
}

function removeMarkup(text) {
  const tmp = document.createElement('div');
  tmp.innerHTML = text;
  const nakedText = tmp.innerText;
  return nakedText;
}

const badge5mColor = '#f8f9fa';
const badge10mColor = '#eeeff0';
const badge15mColor = '#dfe0e1';
const badge30mColor = '#c4c5c6';
const badge60mColor = '#b7b8b9';
const badge2hColor = '#abacad';
const badge4hColor = '#9a9b9c';

function badgeColor(minutes) {
  if (minutes >= 240) {
    return badge4hColor;
  } else if (minutes >= 120) {
    return badge2hColor;
  } else if (minutes >= 60) {
    return badge60mColor;
  } else if (minutes >= 30) {
    return badge30mColor;
  } else if (minutes >= 15) {
    return badge15mColor;
  } else if (minutes >= 10) {
    return badge10mColor;
  } else {
    return badge5mColor;
  }
}

export function createItemHtml(item) {
  let html;
  let title = markdown(item.title);
  if (item.wasInstanceOf) {
    title = iconExclamationSquare + ' ' + title;
  }
  const nakedTitle = removeMarkup(title);
  if (item.done) { // remove markdown
    title = nakedTitle;
  }
  let add = '';
  if (item.notes && item.notes.trim().length > 0) {
    add += iconNote;
  }
  if (item.due && item.due.time) {
    const over = dueTimeStatus(item);
    title = `<span class="badge badge-pill badge-light mt-1 due-time ${over}">${item.due.time}</span> ` + title;
  }
  if (item.duration) {
    const minutes = durationToMinutes(item.duration);
    const title = formatDurationTitle(minutes);
    const color = badgeColor(minutes);
    const textColor = getTextColorForBg(color);
    add += `<span class="badge badge-pill badge-light mt-2 mr-0 py-1 duration" title="${title}" style="background-color: ${color}; color: ${textColor};">${item.duration}</span>`; 
  } else if (item.due) {
    add += `<span class="text-danger mt-2">${iconCircleFill}</span>`;
  }
  if (item.done) {
    html = `<del class="text-muted"><small title="${nakedTitle}">${title}</small></del>`;
  } else {
    html = `<small title="${nakedTitle}">${title}</small>`;
  }
  if (add.length > 0) {
    html += '<span class="todo-hints"> ' + add + '</span>';
  }
  const checked = item.done ? ' checked="checked"' : '';
  let notes = '';
  if (item.notes) {
    notes = markdownParagraph(item.notes);
  }
  let prioString = '';
  if (isEnabled('debug-prio')) {
    prioString += item.priority;
    if (item.prevprio) {
      const len = item.prevprio.length;
      if (len > 1) {
        prioString += '.' + item.prevprio[len-2].prio;
      }
      if (len > 2) {
        prioString += '.' + item.prevprio[len-3].prio;
      }
      if (len > 3) {
        prioString += '.' + item.prevprio[len-4].prio;
      }
    }
  }
  const tm = tagMarker(item);
  html = `${tm}<span class="priority-hint">${prioString}</span><input data-priority="${item.priority}" class="form-control-lg custom-control-input mt-2" type="checkbox" ${checked} value="" id="${item.id}" data-deleted="${item.deleted}" data-instanceof="${item.instanceOf}">
    <label class="custom-control-label todo-title" for="${item.id}">${html}</label>`;
  return html;
}

export function markdownParagraph(text) {
  return md.render(text);
}

export function markdown(text) {
  return md.renderInline(text);
}

export function dirty() {
  document.body.classList.add('dirty');
}

export function setErrorState() {
  document.body.classList.add('error');
}

export function offline() {
  clean();
  document.body.classList.add('offline');
}

export function clean() {
  document.body.classList.remove('dirty');
  document.body.classList.remove('error');
  document.body.classList.remove('offline');
}

export function row() {
  const div = document.createElement('div');
  div.classList.add('row');
  return div;
}

export function col() {
  const div = document.createElement('div');
  div.classList.add('col');
  return div;
}

export function blinkButton(title, id) {
  const btn = document.createElement('button');
  btn.innerHTML = `<span class="blink-2">${title}</span>`;
  btn.classList.add('btn', 'btn-warning', 'm-1', 'p-2');
  btn.id = id;
  return btn;
}

export function bucketHtml(bucket) {
  const id = bucket.target;
  const title = bucket.title;
  const div = document.createElement('div');
  div.classList.add('col', 'bucket');
  const html = `
  <div class="card" id="${id}" data-bucketid="${bucket.bucketId}">
    <div class="card-body">
      <h5 class="card-title">
        <span class="title" title="${bucket.type}:${bucket.typeOrder}">${title}</span>  
        <small class="duration"></small>
      </h5>
      <p class="bucket-info">
        <span class="title-date"></span> <button type="button" class="bucket-done btn btn-outline-secondary btn-sm"></button>
      </p>
      <div class="form-group bucket-todos card-text">
      </div>
    </div>
  </div>
  `;
  div.innerHTML = html;
  return div;
}

export function updateRowCounter(tableEl) {
  let ctr = 0;
  tableEl.querySelectorAll('.row-ctr').forEach((ctrEl) => {
    ctrEl.innerText = ++ctr;
  });
}

export function selectBox(name, values, selected) {
  let html = `<select class="custom-select custom-select-sm custom-select-inline w-auto" name="${name}">\n`;
  values.forEach((value => {
    const sel = value.key === selected ? 'selected' : '';
    html += `<option value="${value.key}" ${sel}>${escapeHtml(value.name)}</option>\n`;
  }));
  return html + '</selected>\n';
}


export function updateSigninStatus(id, isSignedIn, name, signedInCallback, signedOutCallback) {
  if (isSignedIn) {
    document.body.classList.add(id + '-connected');
    console.log(id + ": signed in");
    updateUsername(id, name);
    if (signedInCallback) {
      signedInCallback();
    }
  } else {
    document.body.classList.remove(id + '-connected');
    if (signedOutCallback) {
      signedOutCallback();
    }
    console.log(id + ": NOT signed in");
  }
}

export function updateUsername(id, name) {
  document
  .getElementById(id + '-authentication')
  .querySelector('.username')
  .innerText = '(' + name + ')';
}


import { translate } from './i18n.js';
import jquery from 'jquery';
import bootstrap from 'bootstrap';

function fmt(text, ...args) {
  return text.replace(/{(\d+)}/g, function(match, number) { 
    return typeof args[number] !== 'undefined' ? args[number] : match;
  });
}

export function toast(text, color, ...args) {
  return toastWithDelay(2000, text, color, ...args);
}

export function toastNoHide(text, color, ...args) {
  hideAllNoHideToasts();
  return toastWithDelay(false, text, color, ...args);
}

export function hideAllNoHideToasts() {
  jquery(document.querySelectorAll('div.toast-no-hide')).toast('hide');
}

function toastWithDelay(delay, text, color, ...args) {
  color = color || '#007aff';
  let spinner = '';
  if (!delay) {
    spinner = `&nbsp;<span class="spinner-border spinner-border-sm ml-2 mt-2" role="status" aria-hidden="true"></span>`;
  }
  const content = `
  <div class="toast-body">
  <svg class="bd-placeholder-img rounded mr-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="${color}"></rect></svg>
    ${fmt(translate(text), ...args)}${spinner}
  </div>`;
  const el = document.createElement('div');
  el.classList.add('toast');
  if (!delay) {
    el.classList.add('toast-no-hide');
  }
  el.innerHTML = content;
  const container = document.getElementById('toast-container');
  container.appendChild(el);
  const jq = jquery(el);
  jq.on('hidden.bs.toast', function () {
      container.removeChild(el);
    });
  if (delay) {
    jq.toast({delay: delay});
  } else {
    jq.toast({autohide: false});
  }
  jq.toast('show');
  return jq;
}

export function toastError(text, color, ...args) {
  color = color || '#007aff';
  const content = `
  <div class="toast-body">
    ${fmt(translate(text), ...args)}
  </div>`;
  const el = document.createElement('div');
  el.classList.add('toast');
  el.style = 'border: 2px solid red; border-radius: 4px;';
  el.innerHTML = content;
  const container = document.getElementById('toast-container');
  container.appendChild(el);
  jquery(el)
    .on('hidden.bs.toast', function () {
      container.removeChild(el);
    })
    .on('click', function () {
      jquery(this).toast('hide');
    })
    .toast({autohide: false})
    .toast('show');
}

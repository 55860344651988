import {scheduleSyncStore, syncRead} from './dropbox-sync.js';
import {todoMap} from './items.js';
import {fmtTime} from './dates.js';
import { dirty } from './html.js';
import { applyAutotags } from './tags.js';

export function loadTodos() {
  let string = localStorage.getItem('todos');
  if (string && 'null' !== string) {
    const todoMap = new Map();
    const todos = JSON.parse(string);
    todos.forEach(todo => {
      if (todo) {
        todoMap.set(todo.id, todo);
      }
    });
    return todoMap;
  } else {
    syncRead();
    return new Map();
  }
}

export function loadCalendar(calId) {
  let string = localStorage.getItem('calendar-' + calId);
  if (string) {
    return JSON.parse(string);
  } else {
    return {};
  }
}



let saveTimer = null;

export function saveTodos() {
  flagSyncDirty();
  if (saveTimer) {
    clearTimeout(saveTimer);
    saveTimer = null;
  }
  saveTimer = setTimeout(function () {actualSaveTodos();}, 100);
}

function actualSaveTodos() {
  actualSaveTodosNoSync();
  scheduleSyncStore();
}

export function actualSaveTodosNoSync() {
  const noUnmodifiedRepeats = removeUnmodifiedRepeats(Array.from(todoMap.values()));
  const copy = JSON.parse(JSON.stringify(noUnmodifiedRepeats));
  copy.forEach(todo => cleanupForSave(todo));
  localStorage.setItem('todos', JSON.stringify(copy));
}

export function overwriteTodos(string) {
  const json = JSON.parse(string);
  json.forEach(todo => {
    if (todo) {
      applyAutotags(todo);
    }
  });
  localStorage.setItem('todos', JSON.stringify(json));
}

function removeUnmodifiedRepeats(todoArray) {
  const copy = [];
  todoArray.forEach(todo => {
    if (!todo.new) {
      copy.push(todo);
    }
  });
  return copy;
}

function cleanupForSave(todo) {
  if (todo.bucketPriorities) {
    delete todo.bucketPriorities;
  }
  if (typeof(todo.done) !== 'undefined' && todo.done === false) {
    delete todo.done;
  }
  if (todo.priority === -1) {
    delete todo.priority;
  }
  if (todo.duration === '') {
    delete todo.duration;
  }
  if (todo.notes === '') {
    delete todo.notes;
  }
  if (todo.due) {
    const time = todo.due.time;
    if (time === null) {
      delete todo.due.time;
    } else if (typeof(time) === 'string') {
      if (time.length > 5) {
        todo.due.time = fmtTime(time);
      }
    }
  }
  if (todo.original && todo.original.duration === '') {
    delete todo.original.duration;
  }
}

export function saveCalendar(calendarId, calendarMap) {
  localStorage.setItem('calendar-' + calendarId, JSON.stringify(calendarMap));
}

export function saveShownTourItems(shown) {
  localStorage.setItem('shown-tour-items', JSON.stringify(shown));
}

export function loadShownTourItems() {
  const string = localStorage.getItem('shown-tour-items');
  if (!string) {
    return [];
  }
  const items = JSON.parse(string);
  return items;
}

export function loadVersion() {
  const versionString = localStorage.getItem('todos-version');
  const version = parseInt(versionString) || 0;
  console.log("Loaded version: " + version);
  return version;
}

export function saveVersion(version) {
  localStorage.setItem('todos-version', version);
  console.log("Saved version: " + version);
}

export function resetSync() {
  saveVersion(0);
  flagSyncClean();
}

export function loadLastSyncTime() {
  const date = localStorage.getItem('last-sync-time');
  if (date) {
    return new Date(JSON.parse(date));
  } else {
    return false;
  }
}

export function saveLastSyncTime(date) {
  localStorage.setItem('last-sync-time', JSON.stringify(date));
}

export function flagSyncClean() {
  localStorage.setItem('in-sync-version', loadVersion());
}

export function isSyncClean() {
  const syncString = localStorage.getItem('in-sync-version');
  const syncVersion = parseInt(syncString) || -1;
  if (syncVersion < 0) {
    console.log(`Unknown sync state. Assuming clean.`);
    return true;
  }
  const version = loadVersion();
  if (syncVersion === version) {
    console.log(`Sync is clean: ${syncVersion} === ${version}.`);
    return true;
  } else {
    console.log(`Sync is dirty: ${syncVersion} !== ${version}.`);
    return false;
  }
}

export function flagSyncDirty() {
  dirty();
  localStorage.removeItem('in-sync-version');
}

export function loadCalendarList() {
  let cals = localStorage.getItem('google-calendars');
  if (!cals) {
    return null;
  }
  return JSON.parse(cals);
}

export function saveCalendarList(cals) {
  localStorage.setItem('google-calendars', JSON.stringify(cals));
}



import {ui_de, texts_de} from './i18n.de.js';
const lang = navigator.language || navigator.userLanguage; 
let ui = {};
let texts = {};

export let uiLanguage = 'en';
if (lang === 'de' || lang.startsWith('de-')) {
  setUiLanguage('de');
} else {
  setUiLanguage('en');
}

export function setUiLanguage(iso6391) {
  uiLanguage = iso6391;
  if (iso6391 === 'de') {
    ui = ui_de;
    texts = texts_de;
  } else if (iso6391 === 'en') {
    ui = {};
    texts = {};
  } else {
    throw 'Unsupported language ' + iso6391;
  }
}

export function translateUi(element) {
  element = element || document;
  for (const [key, value] of Object.entries(ui)) {
    if (key.endsWith(':title')) {
      translateAttribute('title', element, key, value);
    } else if (key.endsWith(':placeholder')) {
      translateAttribute('placeholder', element, key, value);
    } else {
      translateContent(element, key, value);
    }
  }
}

function translateContent(element, i18nKey, i18nValue) {
  const els = element.querySelectorAll(i18nKey);
  if (els.length === 0) {
    return;
  }
  els.forEach(el => {
    const title = el.querySelector('.title');
    if (title) {
      title.innerHTML = i18nValue;
    } else {
      el.innerHTML = i18nValue;
    }
  });
}

function translateAttribute(attr, element, i18nKeyWithSuffix, i18nValue) {
  const i18nKey = i18nKeyWithSuffix.substring(0, i18nKeyWithSuffix.length - attr.length - 1);
  const els = element.querySelectorAll(i18nKey);
  if (els.length === 0) {
    return;
  }
  els.forEach(el => {
    el.setAttribute(attr, i18nValue);
  });
}

export function translate(text) {
  const trns = texts[text];
  if (!trns) {
    console.log('Translation missing: ' + text);
  }
  return trns || text;
}
import {isTablet, openNewTab} from './browser.js';
import {cloneRepeated, copyItem, getHighestPriority, purgeTodos, setPriority, todoMap} from './items.js';
import {saveTodos} from './storage.js';
import {previousWeek, nextWeek, thisWeek} from './nav.js';
import { createItemHtml, itemPopoverBody, itemPopoverTitle } from './html.js';
import { openAddTodoModal, openEditModalForTodo } from './add-edit-modals.js';

import jquery from 'jquery';
import Sortable from 'sortablejs';
import { showWeek } from './index.js';
import { allPopovers, popovers } from './ui.js';
import { openSearchModal, updateSearchData } from './search.js';
import { isEnabled } from './features.js';
import { dayFromNow, durationToMinutes, fmtDate, fmtDay, fmtDayShort, isDay, isFriday, isNotWeekend, isToday, millisPerMinute, plusDay, plusWeek, startOfDay, startOfWeek, titleDateDay } from './dates.js';
import { forceSync, syncPeek } from './dropbox-sync.js';
import confetti from 'canvas-confetti';
import { checkLoadingModalHide } from './modal.js';
import { translate, translateUi } from './i18n.js';
import { startTimer, toggleTimer } from './timer.js';
import { settings } from './settings.js';
import { isTourOpen, menuTour, todoDetailsTour } from './tour.js';
import { sort } from 'semver';
import { initTagSelection } from './tags.js';

export function addEvent(type, selector, handler) {
  document.addEventListener(type, function(e) {
    for (let target = e.target; target && target !== this; target = target.parentNode) {
      if (target.matches(selector)) {
        handler.call(target, e);
        break;
      }
    }
  }, false);
}

export function addChildEvent(baseEl, type, selector, handler) {
  baseEl.addEventListener(type, function(e) {
    for (let target = e.target; target && target !== this; target = target.parentNode) {
      if (target.matches(selector)) {
        handler.call(target, e);
        break;
      }
    }
  }, false);
}

function addEventExactTarget(type, selector, handler) {
  document.addEventListener(type, function(e) {
    const target = e.target;
    if (target.matches(selector)) {
      handler.call(target, e);
    }
  }, false);
}

function hasClickedInside(evtTarget, selector) {
  for (let target = evtTarget; target && target !== document; target = target.parentNode) {
    if (target.matches(selector)) {
      return true;
    }
  }
  return false;
}

let windowWidth = window.innerWidth;
export function initAllEvents() {
  generalClickOutsideListener();
  window.onresize = function (evt) {
    const newWidth = window.innerWidth;
    if (newWidth !== windowWidth) {
      windowWidth = newWidth;
      showWeek(evt);
    }
  };
  menu();
  changeWeek();
  markDoneUndone();
  autoCheckDueForDateValue();
  initDurationButtons();
  addTodoByClickInBucket();
  changeTodoDoneVisibility();
  clickTodo();
  searchForm();
  document.getElementById('add-todo-button').addEventListener('click', function () {
    closeHamburger();
    openAddTodoModal();

  });
  document.getElementById('sync-save-button').addEventListener('click', function () {
    closeHamburger();
    forceSync();
  });
  document.getElementById('purge-todos-button').addEventListener('click', function () {
    closeHamburger();
    purgeTodos();
  });
  document.getElementById('to-search').addEventListener('click', function () {
    closeHamburger();
    openSearchModal();
  });
  if (isEnabled('delete-all')) {
    document.getElementById('delete-all').addEventListener('click', function () {
      closeHamburger();
      todoMap.clear();
      showWeek();
    });
  }
  initTagSelection();
  initNewDayRefresh();
  initWindowHandling();
}

function generalClickOutsideListener() {
  document.addEventListener('click', function (evt) {
    justClosePopoverIfClickedOutside(evt);
    justCloseHambugerMenuIfClickedOutside(evt);
  });
}

function justCloseHambugerMenuIfClickedOutside(evt) {
  const menu = document.getElementById('hamburger-menu');
  if (menu.style.display === 'block') {
    if (!hasClickedInside(evt.target, '#hamburger-menu')) {
      closeHamburger();
      evt.preventDefault();
      evt.stopPropagation();
      evt.stopImmediatePropagation();
    }
  }
}

function justClosePopoverIfClickedOutside(evt) {
  if (!hasClickedInside(evt.target, '.popover.show')) {
    closeAnyPopover(evt);
  }
}

export function closeAnyPopover(evt) {
  const popover = document.querySelector('.popover.show');
  if (popover) {
    jquery(popover).popover('hide');
    evt.preventDefault();
    evt.stopPropagation();
    evt.stopImmediatePropagation();
  }
}

function initNewDayRefresh() {
  let lastRefresh = new Date();
  window.addEventListener('focus', function (e) {
    const now = new Date();
    if (now.getDay() !== lastRefresh.getDay()) {
      console.log(`Discovered new day at ${now}, trigger page refresh.`);
      lastRefresh = now;
      thisWeek();
    }
  });
}

function initWindowHandling() {
  window.addEventListener('focus', function (e) {
    checkLoadingModalHide();
    syncPeek();
  });
}

function searchForm() {
  document.getElementById('search-input').addEventListener('input', updateSearchData);
  document.getElementById('search-check-repeated').addEventListener('change', updateSearchData);
  document.getElementById('search-check-instance').addEventListener('change', updateSearchData);
  document.getElementById('search-check-single').addEventListener('change', updateSearchData);
  document.getElementById('search-check-deleted').addEventListener('change', updateSearchData);
  document.getElementById('search-check-done').addEventListener('change', updateSearchData);
  document.getElementById('search-check-imported').addEventListener('change', updateSearchData);
  addEvent('click', '.search-todo', function (evt) {
    evt.preventDefault();
    let target = evt.target;
    if (!target.classList.contains('search-todo')) {
      target = target.closest('.search-todo');
    }
    const id = target.id;
    openEditModalForTodo(id);
  });
}

function openHamburger() {
  document.getElementById('hamburger').style.display = 'none';
  document.getElementById('hamburger-open').style.display = 'block';
  document.getElementById('hamburger-menu').style.display = 'block';
  menuTour();
}

export function closeHamburger() {
  if (isTourOpen()) {
    return;
  }
  document.getElementById('hamburger').style.display = 'block';
  document.getElementById('hamburger-open').style.display = 'none';
  document.getElementById('hamburger-menu').style.display = 'none';
}

function menu() {
  document.getElementById('hamburger').addEventListener('click', function (evt) {
    openHamburger();
    evt.stopPropagation();
    evt.stopImmediatePropagation();
  });
  document.getElementById('hamburger-open').addEventListener('click', function () {
    closeHamburger();
  });
  addEvent('click', '#timerMenu .timer-button', function (evt) {
    evt.preventDefault();
    startTimer(durationToMinutes(this.innerText));
  });
  document.getElementById('timer').addEventListener('click', toggleTimer);
}

function clickTodo() {  
  let dblClickTimer = null;
  addEvent('dblclick', '.card-body .todo-container label', function (evt) {
    if (evt.offsetX < 0) { // ::before -> the checkbox
      return false;
    }
    if (dblClickTimer !== null) {
      clearTimeout(dblClickTimer);
      dblClickTimer = null;
    }
    const id = this.closest('.todo-container').querySelector('input').id;
    openEditModalForTodo(id);
  });
  addEvent('click', '.card-body .todo-container label', function (evt) {
    if (evt.offsetX < 0) { // ::before -> the checkbox
      return false;
    }
    evt.preventDefault();
    if (dblClickTimer === null) {
      const elem = this;
      const clickEvt = evt;
      dblClickTimer = setTimeout(() => {
        singleClickTodo(elem, clickEvt);
        dblClickTimer = null;
      }, 100);
    }
  });
  addEvent('click', '.todo-popover a', function (evt) {
    evt.preventDefault();
    let url = evt.target.getAttribute('href');
    if (url.toLowerCase().indexOf('://') < 0) {
      url = 'http://' + url;
    }
    openNewTab(url);
  });
}

const plusLarge = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/></svg>';
const arrowRight = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/></svg>';
const chevronBarUp = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-bar-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M3.646 11.854a.5.5 0 0 0 .708 0L8 8.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708zM2.4 5.2c0 .22.18.4.4.4h10.4a.4.4 0 0 0 0-.8H2.8a.4.4 0 0 0-.4.4z"/></svg>';
const chevronUp = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/></svg>';
const chevronBarDown = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-bar-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M3.646 4.146a.5.5 0 0 1 .708 0L8 7.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zM1 11.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"/></svg>';
const chevronDown = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>';

/**
 * Click behavior: 
 * <ul>
 * <li>if target is a link => open link in new tab
 * <li>if popup is open => close popup
 * <li>if target is a todo => open todo popup
 * </ul>
 */
function singleClickTodo(elem, evt) {
  if (evt.target.tagName.toLowerCase() === 'a') {
    openNewTab(evt.target.getAttribute('href'));
  } else {
    const base = jquery(elem).closest('label');
    const container = elem.closest('.todo-container');
    const id = jquery(elem).closest('.todo-container').find('input').get(0).id;
    const item = todoMap.get(id);
    let dateButtons = '';
    let dueDate;
    let dueType;
    const isTop = item.priority === 0;
    const isBottom = container.nextElementSibling === null || !container.nextElementSibling.classList.contains('todo-container');
    if (!item.done) {
      let addClass = ' ml-2';
      dateButtons += dateModButtons([
        [chevronBarUp, 'Move up', 'to-top', isTop],
        [chevronBarDown, 'Move down', 'to-bottom', isBottom]
      ]);
      if (item.due) {
        dueDate = item.due.date;
        dueType = item.due.type;
        if (dueType === 'week') {
          dateButtons += dateModBtnGroup(plusLarge + '1w', 'Move forward one week', 'plus-1w' + addClass, 'to-plus-day');
        } else {
          dateButtons += dateModBtnGroup(plusLarge + '1d', 'Move forward one day', 'plus-1d' + addClass, 'to-plus-day');
        }
        addClass= ' ml-2';
      }
      if (dueDate && isToday(dueDate)) {
        if (isNotWeekend(new Date())) {
          dateButtons += dateModBtnGroup(arrowRight + translate('Sat'), 'Move to next Saturday', 'to-saturday' + addClass, 'to-other-day');
        } else {
          dateButtons += dateModBtnGroup(arrowRight + translate('Mon'), 'Move to next Monday', 'to-monday' + addClass, 'to-other-day');
        }
      } else {
        dateButtons += dateModBtnGroup(arrowRight + translate('Today'), 'Move to today', 'to-0' + addClass, 'to-other-day');
      }
    }
    if (!base.data("bs.popover")) {
      base.popover({
        html: true,
        trigger: 'manual',
        boundary: 'window',
        animation: false,
        placement: 'auto',
        title: itemPopoverTitle(item),
        content: itemPopoverBody(item),
        sanitize: false,
        template: `<div class="popover todo-popover" role="tooltip">
          <div class="arrow"></div>
          <h3 class="popover-header"></h3>
          <div class="popover-body"></div>
          <div class="popover-footer">
            ${dateButtons}
            <button type="button" class="btn btn-outline-dark clone ml-5 px-3 py-2" title="Duplicate Todo">Clone</button>
            <button type="button" class="btn btn-warning edit px-3 py-2">Edit</button>
            <button type="button" class="btn btn-danger delete px-3 py-2">Delete</button>
            ${ribbonPlusDay(dueType)}
            ${ribbonToDay(dueDate)}
          </div>
        </div>`
      })
      .one('shown.bs.popover', () => {
        setTimeout(() => {
          if (jquery(base.data("bs.popover").tip).hasClass('show')) {
            todoDetailsTour();
          }
        }, 100);
      });
    }
    if (jquery(base.data("bs.popover").tip).hasClass('show')) {
      base.popover('hide');
      const el = jquery(base.data("bs.popover").tip).get(0);

    } else {
      allPopovers().popover('hide');
      base.popover('show');
      const el = jquery(base.data("bs.popover").tip).get(0);
      el.querySelector('.edit').addEventListener('click', todoPopoverEditClick);
      el.querySelector('.delete').addEventListener('click', todoPopoverDeleteClick);
      onClickIfExists(el, '.clone', todoClone);
      onClickIfExists(el, '.to-plus-day', showPopoverPlus);
      onClickIfExists(el, '.to-other-day', showPopoverTo);
      onClickIfExists(el, '.to-monday', todoPopoverToMondayClick);
      onClickIfExists(el, '.to-saturday', todoPopoverToSaturdayClick);
      onClickIfExists(el, '.to-0', todoPopoverToTodayClick);
      onClickIfExists(el, '.to-1', evt => todoPopoverToXClick(evt, 1));
      onClickIfExists(el, '.to-2', evt => todoPopoverToXClick(evt, 2));
      onClickIfExists(el, '.to-3', evt => todoPopoverToXClick(evt, 3));
      onClickIfExists(el, '.to-4', evt => todoPopoverToXClick(evt, 4));
      onClickIfExists(el, '.to-5', evt => todoPopoverToXClick(evt, 5));
      onClickIfExists(el, '.to-6', evt => todoPopoverToXClick(evt, 6));
      onClickIfExists(el, '.to-7', evt => todoPopoverToXClick(evt, 7));
      onClickIfExists(el, '.plus-1d', evt => todoPopoverPlusXClick(evt, 1, 'day'));
      onClickIfExists(el, '.plus-2d', evt => todoPopoverPlusXClick(evt, 2, 'day'));
      onClickIfExists(el, '.plus-7d', evt => todoPopoverPlusXClick(evt, 7, 'day'));
      onClickIfExists(el, '.plus-1w', evt => todoPopoverPlusXClick(evt, 1, 'week'));
      onClickIfExists(el, '.plus-2w', evt => todoPopoverPlusXClick(evt, 2, 'week'));
      onClickIfExists(el, '.plus-4w', evt => todoPopoverPlusXClick(evt, 4, 'week'));

      onClickIfExists(el, '.to-top', todoPopoverMoveTop);
      onClickIfExists(el, '.to-bottom', todoPopoverMoveBottom);
      onClickIfExists(el, '.to-up', todoPopoverMoveUp);
      onClickIfExists(el, '.to-down', todoPopoverMoveDown);

      translateUi(el);
    }
  }
} 

function ribbonPlusDay(dueType) {
  let ribbon = '<div class="plus-day ribbon d-none pt-2">';
  let plusCls = '';
  if (dueType === 'day') {      
    ribbon +=
      dateModButton('+1d', 'Move to next day', 'plus-1d') +
      dateModButton('+2d', 'Move two days', 'plus-2d') +
      dateModButton('+7d', 'Move seven days', 'plus-7d');
    plusCls = 'ml-4';
  }
  ribbon +=
    dateModButton('+1w', 'Move to next week', plusCls + ' plus-1w') +
    dateModButton('+2w', 'Move two weeks', 'plus-2w') +
    dateModButton('+4w', 'Move four weeks', 'plus-4w');
  ribbon += '</div>';
  return ribbon;
}

function ribbonToDay(dueDate) {
  let ribbon = '<div class="to-day ribbon d-none pt-2 ">';
  if (!isToday(dueDate)) {
    ribbon += dateModButton('&#10132;' + translate('Today'), translate('Move to Today'), 'to-0');
  }
  if (!isDay(dueDate, dayFromNow(1))) {
    ribbon += dateModButton('&#10132;' + translate('Tomorrow'), translate('Move to Tomorrow'), 'to-1');
  }
  ribbon +=
    toDayFromNowButton(dueDate, 2) +
    toDayFromNowButton(dueDate, 3) +
    toDayFromNowButton(dueDate, 4) +
    toDayFromNowButton(dueDate, 5) +
    toDayFromNowButton(dueDate, 6) +
    toDayFromNowButton(dueDate, 7);
  ribbon += '</div>';
  return ribbon;
}

function toDayFromNowButton(dueDate, numDays) {
  const destDay = dayFromNow(numDays);
  if (!isDay(dueDate, destDay)) {
    const nameShort = fmtDayShort(destDay);
    const name = fmtDay(destDay);
    return dateModButton('&#10132;' + nameShort, 'Move to ' + name + ', ' + titleDateDay(destDay), 'to-' + numDays);
  }
  return '';
}

function dateModBtnGroup(title, description, mainCls, dropDownCls) {
  return `<div class="btn-group">
    <button type="button" 
      title="${description}" 
      class="btn btn-outline-dark ${mainCls} px-2 py-1">${title}</button>
    <button type="button"
      class="btn btn-outline-dark dropdown-toggle dropdown-toggle-split ${dropDownCls}" aria-haspopup="true">
      <span class="sr-only">Toggle Dropdown</span>
    </button>
  </div>`;
}


function dateModButtons(titleDescriptionClsDisabledArray) {
  let html = '';
  for (let i = 0; i < titleDescriptionClsDisabledArray.length; i++) {
    const el = titleDescriptionClsDisabledArray[i];
    const disabled = el.length > 2 && el[3];
    const button = dateModButton(el[0], el[1], el[2], disabled);
    html += button;
  }
  return '<div class="btn-group">' + html + '</div>';
}

function dateModButton(title, description, cls, disabled) {
  let disabledString = '';
  if (disabled) {
    disabledString = ' disabled';
  }
  return ` <button type="button" 
    title="${description}" ${disabledString}
    class="btn btn-outline-dark ${cls} px-3 py-2">${title}</button>`;

}

function onClickIfExists(parentEl, selector, action) {
  const clickEl = parentEl.querySelectorAll(selector);
  clickEl.forEach(el => el.addEventListener('click', action));
}

function todoClone(evt) {
  const id = this.closest('.todo-popover').querySelector('.popover-header > span').dataset.id;
  copyItem(todoMap.get(id));
  showWeek();
}

function todoPopoverEditClick(evt) {
  const po = this.closest('.todo-popover');
  const id = po.querySelector('.popover-header > span').dataset.id;
  jquery(po).popover('hide');
  setTimeout(() => openEditModalForTodo(id), 100);
}

function todoPopoverDeleteClick(evt) {
  const id = this.closest('.todo-popover').querySelector('.popover-header > span').dataset.id;
  const item = todoMap.get(id);
  item.deleted = new Date();
  delete item.new;
  saveTodos();
  showWeek();
}

function popoverMoveAction(el, dateAction, type) {
  const id = el.closest('.todo-popover').querySelector('.popover-header > span').dataset.id;
  const item = todoMap.get(id);
  const oldDate = item.due ? item.due.date : undefined;
  const newDate = dateAction(oldDate);
  handleInstanceOfMove(item, type, newDate);
  if (!item.due) {
    item.due = {};
  }
  item.due.date = newDate;
  item.due.type = type;
  if (item.due.time) {
    delete item.priority;
  } else {
    const lastPrio = getHighestPriority(newDate, type, item.done, id);
    setPriority(item, lastPrio+1, 'popover-move');
  }
  saveTodos();
  showWeek();
}

function showPopoverPlus(evt) {
  const popover = this.closest('.todo-popover');
  popover.querySelectorAll('.ribbon').forEach(el => el.classList.add('d-none'));
  const ribbon = popover.querySelector('.plus-day.ribbon');
  ribbon.classList.remove('d-none');
}

function showPopoverTo(evt) {
  const popover = this.closest('.todo-popover');
  popover.querySelectorAll('.ribbon').forEach(el => el.classList.add('d-none'));
  const ribbon = popover.querySelector('.to-day.ribbon');
  ribbon.classList.remove('d-none');
}

function todoPopoverToTodayClick(evt) {
  popoverMoveAction(this, date => fmtDate(new Date()), 'day');
}

function todoPopoverToMondayClick(evt) {
  const nextMonday = new Date();
  nextMonday.setDate(nextMonday.getDate() + (1 + 7 - nextMonday.getDay()) % 7);
  popoverMoveAction(this, date => fmtDate(nextMonday), 'day');
}

function todoPopoverToSaturdayClick(evt) {
  const nextSaturday = new Date();
  nextSaturday.setDate(nextSaturday.getDate() + (6 + 7 - nextSaturday.getDay()) % 7);
  popoverMoveAction(this, date => fmtDate(nextSaturday), 'day');
}

function todoPopoverToXClick(evt, days) {
  popoverMoveAction(evt.target, date => fmtDate(plusDay(new Date(), days)), 'day');
}

function todoPopoverMoveUp(evt) {
  const id = this.closest('.todo-popover').querySelector('.popover-header > span').dataset.id;
  const item = todoMap.get(id);

  const prevId = getPreviousTodoId(id, item.done);
  if (prevId) {
    const prev = todoMap.get(prevId);
    if (prev) {
      const prio1 = prev.priority;
      const prio2 = item.priority;
      setPriority(item, prio1, 'move-up-u');
      setPriority(prev, prio2, 'move-up-d');    
      saveTodos();
      showWeek();
    }
  }
}

function todoPopoverMoveDown(evt) {
  const id = this.closest('.todo-popover').querySelector('.popover-header > span').dataset.id;
  const item = todoMap.get(id);

  const nextId = getNextTodoId(id, item.done);
  if (nextId) {
    const next = todoMap.get(nextId);
    if (next) {
      const prio1 = item.priority;
      const prio2 = next.priority;
      setPriority(next, prio1, 'move-down-u');
      setPriority(item, prio2, 'move-down-d');    
      saveTodos();
      showWeek();
    }
  }
}

function getAllTodoContainers(id, done) {
  const el = document.getElementById(id);
  if (done) {
    return el.closest('.bucket-todos').querySelectorAll('.todo-container.todo-done');
  } else {
    return el.closest('.bucket-todos').querySelectorAll('.todo-container:not(.todo-done)');
  }
}

function getTodoContainerPrio(container) {
  const input = container.querySelector('input');
  const prio = input.dataset.priority*1;
  return prio;
}

function getPreviousTodoId(id, done) {
  const all = getAllTodoContainers(id, done);
  let prevId = false;
  for (let i = 0; i < all.length; i++) {
    const cnt = all[i];
    const input = cnt.querySelector('input');
    if (input.id === id) {
      return prevId;
    }
    prevId = input.id;
  }
  return false;
}

function getNextTodoId(id, done) {
  const all = getAllTodoContainers(id, done);
  let match = -1;
  for (let i = 0; i < all.length; i++) {
    const cnt = all[i];
    const input = cnt.querySelector('input');
    if (input.id === id) {
      match = i;
      break;
    }
  }
  if (match >= 0) {
    if (all.length > match+1) {
      const nextContainer = all[match+1];
      const input = nextContainer.querySelector('input');
      return input.id;
    }
  }
  return false;
}

function getFirstTodoPrio(id, done) {
  const all = getAllTodoContainers(id, done);
  const prio = getTodoContainerPrio(all[0]);
  return prio;
}

function getLastTodoPrio(id, done) {
  const all = getAllTodoContainers(id, done);
  const prio = getTodoContainerPrio(all[all.length-1]);
  return prio;
}

function todoPopoverMoveTop(evt) {
  const id = this.closest('.todo-popover').querySelector('.popover-header > span').dataset.id;
  const item = todoMap.get(id);
  const firstPrio = getFirstTodoPrio(id, item.done);
  setPriority(item, firstPrio-1, 'move-top');
  saveTodos();
  showWeek();
}

function todoPopoverMoveBottom(evt) {
  const id = this.closest('.todo-popover').querySelector('.popover-header > span').dataset.id;
  const item = todoMap.get(id);
  const lastPrio = getLastTodoPrio(id, item.done);
  setPriority(item, lastPrio+1, 'move-bottom');
  saveTodos();
  showWeek();
}

function todoPopoverPlusXClick(evt, num, newType) {
  let action;
  if (newType === 'day') {
    action = date => plusDay(date, num);
  } else if (newType === 'week') {
    action = date => plusWeek(date, num);
  } else {
    throw "Unsupported new type: " + newType;
  }
  popoverMoveAction(evt.target, action, newType);
}

function changeWeek() {
  document.getElementById('week-left').addEventListener('click', previousWeek);
  document.querySelectorAll('.navbar-brand')[1].addEventListener('click', function () {
    closeHamburger();
    thisWeek();
  });
  document.getElementById('week-today').addEventListener('click', function () {
    closeHamburger();
    thisWeek();
  });
  document.getElementById('week-right').addEventListener('click', nextWeek);
}

function allDoneInBucket(bucketElement) {
  const numItems = bucketElement.querySelectorAll('.todo-container').length;
  const numItemsDone = bucketElement.querySelectorAll('.todo-container.todo-done').length;
  return numItemsDone === numItems;
}

function markDoneUndone() {
  addEvent('change', '.card-body input', function (evt) {
    evt.preventDefault();
    const id = this.getAttribute('id');
    const bucketElement = this.closest('.card');
    const allDoneBefore = allDoneInBucket(bucketElement);
    const newState = this.checked;
    const item = todoMap.get(id);
    delete item.new;
    const itemDone = !!item.done;
    if (itemDone !== newState) {
      const div = this.closest('.todo-container');
      popovers(div).popover('hide');
      const el = document.getElementById(id);
      let x = 0;
      let y = 0;
      if (el) { // muss doppelt geprüft werden für overdue
        const { left, top, width, height } = el.getBoundingClientRect();
        x = (left + width / 2) / window.innerWidth;
        y = (top + height / 2) / window.innerHeight;
      }
      if (item.repeat) {
        throw "item.repeat of a displayed item is set - this should not happen";
      } else {
        if (newState) {
          item.done = new Date();
        } else {
          item.done = false;
        }
        div.innerHTML = createItemHtml(item);
        if (newState) {
          div.classList.add('todo-done');
        } else {
          div.classList.remove('todo-done');
        }
      }
      saveTodos();
      showWeek();
      const allDoneAfter = allDoneInBucket(bucketElement);
      if (allDoneAfter !== allDoneBefore) {
        if (allDoneAfter && settings.confetti) {
          confetti({
            disableForReducedMotion: true,
            particleCount: 300,
            startVelocity: 30,
            gravity: 2,
            scalar: 0.5,
            spread: 360,
            origin: {x: x, y: y}
          });
        }
      }
    }
  });
}

function changeTodoDoneVisibility() {
  addEventExactTarget('click', '.card-body .bucket-done, .card-body .bucket-done b', function(evt) {
    evt.preventDefault();
    const bucketElement = this.closest('.bucket');
    const done = bucketElement.querySelector('.bucket-done');
    if (bucketElement.classList.contains('show-done')) {
      bucketElement.classList.remove('show-done');
      done.classList.remove('btn-secondary');
      done.classList.add('btn-outline-secondary');
    } else {
      bucketElement.classList.add('show-done');
      done.classList.remove('btn-outline-secondary');
      done.classList.add('btn-secondary');
    }
  });
}

function addTodoByClickInBucket() {
  addEventExactTarget('click', '.card-body, .card-title, .bucket-todos, .fill-element', function(evt) {
    evt.preventDefault();
    const bucketElement = this.closest('.card');
    if (bucketElement.id === 'overdue') {
      return;
    }
    const date = bucketElement.dataset.date;
    const type = bucketElement.dataset.type;
    const dialogElement = document.getElementById('addTodoModal');
    if (bucketElement.id === 'eventually') {
      dialogElement.querySelector('[name="due"]').checked = false;
    } else {
      dialogElement.querySelector('[name="due"]').checked = true;
      dialogElement.querySelector('[name="due-date"]').value = fmtDate(date);
      dialogElement.querySelector('[name="due-type"]').value = type;
      dialogElement.querySelector('[name="due-time"]').value = '';
    }
    openAddTodoModal();
  });
}

function initDurationButtons() {
  Array.from(document.querySelectorAll('.duration-button')).forEach(input => {
    input.addEventListener('click', function () {
      const newDuration = input.innerText === '0' ? '' : input.innerText;
      input.closest('form').querySelector('[name="duration"]').value = newDuration;
    });
  });  
}
function autoCheckDueForDateValue() {
  Array.from(document.querySelectorAll('[name="due-date"]')).forEach(input => {
    input.addEventListener('change', function () {
      const newState = input.value.length > 0;
      input.closest('form').querySelector('[name="due"]').checked = newState;
    });
  });  
}

const sortables = [];
function initSortable(el) {
  const sortOptions = {
    group: {
      name:'buckets'
    },
    animation: 150,
    easing: "cubic-bezier(1, 0, 0, 1)",
    draggable: '.todo-container',
    delayOnTouchOnly: true,
    delay: 100,
    revertOnSpill: true,
    onEnd: onSortEnd
  };
  if (el.closest('.card').id === 'overdue') {
    sortOptions.group.put = false;
  }
  const st = new Sortable(el, sortOptions);
  sortables.push(st);
}

function onSortEnd(evt) {
  if (evt.to === evt.from && evt.oldIndex === evt.newIndex) {
    return;
  }
  const el = evt.item;
  const target = evt.to;
  const tid = todoId(el);
  if (tid) {
    const dragged = todoMap.get(tid);
    delete dragged.new;
    const dropzone = bucketElement(target);
    const subdropzone = inBucketDayElement(target, tid);
    let dueType = 'eventually';
    let dueDate;
    if (dragged.due) {
      dueType = dragged.due.type;
      dueDate = dragged.due.date;
    }
    let dropDate;
    if (subdropzone) {
      dropDate = subdropzone.dataset.date;
    }
    const dropType = dropzone.dataset.type;
    const bucketHasChanged = dropType !== dueType || dropzone.dataset.date !== dueDate;
    if (bucketHasChanged || dropDate !== dueDate) {
      handleInstanceOfMove(dragged, dropType, dropDate || dropzone.dataset.date);
      let time = null;
      if (dragged.due) {
        time = dragged.due.time;
      }
      if (dropzone.dataset.type === 'eventually') {
        delete dragged.due;
      } else if (dropDate) {
        dragged.due = {
          type: 'day',
          date: dropDate,
          time: time
        };
      } else {
        dragged.due = {
          type: dropzone.dataset.type,
          date: dropzone.dataset.date,
          time: time
        };
      }
    }
    const newOrder = todoIdsInBucket(target);
    fixPrioritiesAfterInsertion(newOrder, dragged);
    saveTodos();
    showWeek();
  } else {
    return false;
  }
}

export function dragDrop() {
  killSortables();
  const buckets = document.querySelectorAll('.bucket-todos');
  buckets.forEach(el => initSortable(el));
  console.log(`Added ${buckets.length} sortables.`);
}

function killSortables() {
  for (let i = 0; i < sortables.length; i++) {
    sortables[i].destroy();
  }

  console.log(`Destroyed ${sortables.length} sortables.`);
  sortables.length = 0;
}

function handleInstanceOfMove(todo, newType, newDate) {
  const dueDifferent = todo.due && !newDate || !todo.due && newDate;
  const dueTypeDifferent = todo.due && todo.due.type !== newType;
  const dueDateDifferent = todo.due && fmtDate(todo.due.date) !== fmtDate(newDate);
  if (todo.instanceOf && (dueDifferent || dueTypeDifferent || dueDateDifferent)) {
    // repeat instance needs to stay on its original day
    const copyToDelete = cloneRepeated(todo);
    todo.wasInstanceOf = todo.instanceOf;
    delete todo.instanceOf;
    delete todo.new;
    delete copyToDelete.new;
    copyToDelete.deleted = new Date();
  }
}

const PRIO_GAP = 1;
function fixPrioritiesAfterInsertion(newOrder, inserted) {
  let previousPrio = false;
  for (let i = 0; i < newOrder.length; i++) {
    const thisId = newOrder[i];
    if (inserted.id === thisId) {
      if (!previousPrio) {
        // find first item with same done status
        let found = false;
        for (let j = i + 1; j < newOrder.length; j++) {
          const next = todoMap.get(newOrder[j]);
          if (next.done && inserted.done || !next.done && !inserted.done) {
            setPriority(inserted, next.priority - PRIO_GAP, 'fix:');
            found = true;
            break;
          }
        }
        if (!found) {
          setPriority(inserted, 0, 'fix0');
        }
      } else {
        setPriority(inserted, previousPrio, 'fix.');
      }
      return;
    }
    const item = todoMap.get(thisId);
    if (item.done && inserted.done || !item.done && !inserted.done) {
      previousPrio = item.priority;
      setPriority(item, previousPrio - PRIO_GAP, 'fix-');
    }
  }
}


function todoId(container) {
  const checkbox = container.querySelector('[type="checkbox"]');
  if (checkbox) {
    return checkbox.getAttribute('id');
  }
}
function bucketElement(anyInsideElement) {
  return anyInsideElement.closest('.card');
}

function inBucketDayElement(anyInsideElement, tid) {
  let step = bucketElement(anyInsideElement).querySelector('#'+tid);
  if (step) {
    step = step.closest('.todo-container');
    while (step && step.previousSibling) {
      step = step.previousSibling;
      if (step && step.classList.contains('in-bucket-day')) {
        return step;
      }
    }
  }
  return false;
}

function todoIdsInBucket(anyInsideElement) {
  const bucketTodos = bucketElement(anyInsideElement).querySelectorAll('.todo-container input');
  const ids = [];
  bucketTodos.forEach(todo => {
    ids.push(todo.getAttribute('id'));
  });
  return ids;
}

export function mainTour_de() {
  return [
    ['body.desktop #cw', 'TIO zeigt deine Aufgaben wochenbasierend an. Hier stehen die aktuell angezeigte Kalenderwoche und das genaue Datum.<br><br>' +
      'Du kannst mit Weiter/Zurück oder mit den Pfeiltasten rechts/links durch diese Tour blättern.<br><br>' +
      'Um diese Tour zu schließen, klicke einfach irgendwo außerhalb dieses Hinweises', '0.6.2'],
    ['body:not(.desktop) #cw', 'TIO zeigt deine Aufgaben wochenbasierend an. Hier stehen die aktuell angezeigte Kalenderwoche und das genaue Datum.<br><br>' +
      'Ändere die angezeigte Woche, indem du den Bildschirminhalt mit dem Finger nach links oder rechts wischst.<br><br>' +
      'Du kannst mit Weiter/Zurück durch diese Tour blättern.<br><br>' +
      'Um diese Tour zu schließen, tippe einfach irgendwo außerhalb dieses Hinweises', '0.6.2'],
    ['body:not(.mobile) #week-left', 'Klicke hier um die jeweils vorherige Woche anzuzeigen.'],
    ['body:not(.mobile) #week-today', 'Klicke hier um die aktuelle Woche anzuzeigen.'],
    ['body.desktop.essential-view #week-today', 'Du siehst gerade die Hauptübersicht der aktuellen Woche. Klicke hier um zur Vollansicht zu wechseln.<br><br>Klicke nochmal, um wieder die Hauptansicht zu zeigen.'],
    ['body:not(.mobile) #week-right', 'Klicke hier um die jeweils nächste Woche anzuzeigen.'],
    ['body:not(.mobile) #timerMenuButton', 'Hier kannst du einen einfachen Timer starten. Nach Ablauf ertönt ein Alarm.'],
    ['#hamburger', 'Hier ist ein Menü mit erweiterten Funktionen.<br><br>' +
      'Der Knopf zeigt außerdem den Synchronisierungsstatus, wenn du z.B. TIO mit deiner Dropbox verknüpft hast:' +
      '<ul><li>blau: Alles ist synchron.<li>orange: Der letzte Stand muss noch gespeichert werden.<li>rot: Es gab einen Fehler.</ul>'],
    ['body.desktop .card:not(.overdue)', '<p>Alle Aufgaben in TIO sind in Karten einsortiert. Eine Karte kann für einen Tag, eine Woche oder einen Monat stehen.</p>' +
      '<p>Klicke in den leeren Bereich einer Karte, um eine neue Aufgabe anzulegen.</p>' +
      '<p>Verschiebe Aufgaben per Drag&Drop innerhalb einer Karte und zwischen Karten.</p>'],
    ['body:not(.desktop) .bucket:not(.d-none) .card:not(.overdue)', '<p>Alle Aufgaben in TIO sind in Karten einsortiert. Eine Karte kann für einen Tag, eine Woche oder einen Monat stehen.</p>' +
      '<p>Tippe in den leeren Bereich einer Karte, um eine neue Aufgabe anzulegen.</p>' +
      '<p>Drag&Drop für Aufgaben funktioniert auf dem Handy oder Tablet momentan noch nicht.</p>'],
    ['.bucket:not(.d-none) .card:not(.overdue) .card-title .title', 'Ganz oben in einer Karte steht ihr Name.'],
    ['.bucket:not(.d-none) .card:not(.overdue) .bucket-info .title-date', '...und darunter ihr Datum oder Datumsbereich.'],
    ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done)', '<p>Alle Aufgaben einer Karte werden mit ihrem Titel innerhalb der Karte aufgelistet.</p>' +
      '<p>Klicke auf den Titel der Aufgabe, um weitere Details zu sehen.</p>' +
      '<p>Ein Doppelklick auf den Titel öffnet den Bearbeitungsdialog.</p>'],
    ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done) input[type="checkbox"]', 'Im linken Bereich ist eine Checkbox.<br>Klicke darauf um eine Aufgabe abzuhaken.<br>Sie wird dann ausgeblendet.'],
    ['.bucket:not(.d-none) .card:not(.overdue) .card-title .duration.badge', 'Die Gesamtdauer der Aufgaben einer Karte (wenn angegeben), steht hier.'],
    ['.bucket:not(.d-none) .card:not(.overdue) .bucket-info .bucket-done:not(:empty)', '<p>Hier steht, wie viele Aufgaben die Karte enthält und wie viele davon schon erledigt sind.</p>' +
      '<p>Klicke darauf, um die erledigten Aufgabe ein- oder auszublenden.</p>'],
    ['.bucket:not(.d-none) #overdue:not(.d-none)', 'Alle Aufgaben, die vor der aktuellen Woche schon erledigt sein sollten, werden in der <span style="color:red; font-weight: bold;">Überfällig</span> Karte aufgelistet.'],
    ['.bucket:not(.d-none) #eventually:not(.d-none)', 'Alle Aufgaben ohne Datum werden in der <span style="color:red; font-weight: bold;">Irgendwann</span> Karte aufgelistet.'],
    ['.bucket:not(.d-none) .card-body.nothing-to-do', 'Karten ohne Aufgaben haben eine Sonne als Wasserzeichen.'],
    ['.bucket:not(.d-none) .card-body.all-done', 'Karten in denen alle Aufgaben erledigt sind, haben einen Erledigt-Haken als Wasserzeichen.'],
    ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done) .due-time', 'Soll eine Aufgabe zu einer bestimmten Uhrzeit erledigt werden, wird sie hier angezeigt.'],
    ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done) .duration', 'Die Dauer einer Aufgabe wird hier angezeigt.'],
    ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done) .bi-sticky', 'Aufgaben mit diesem Zeichen enthalten Notizen.'],
  ];
}

export function addModalTour_de() {
  return [
    ['#addTodoModal .modal-content', 'Der Eingabedialog für neue Aufgaben. Ein Titel muss eingegeben werden, der Rest ist optional.<br><br>' +
      'Du solltest aber auf jeden Fall auch ein Fälligkeitsdatum setzen.<br><br>' +
      'Und eine Dauer ist auch immer sinnvoll. So merkst du früh genug, wenn dein Tag zu voll wird.'],
    ['#addTodoTitle', 'Der Titel der Aufgabe, dieser wird dann in der Wochenübersicht angezeigt.<br><br>' +
      'Du kannst Markdown verwenden (wenn du das nicht kennst, schau z.B. bei <a href="https://de.wikipedia.org/wiki/Markdown#Auszeichnungsbeispiele" target="_blank">Wikipedia</a> nach) ' +
      'um Text <code>*<em>kursiv</em>*</code>, <code>**<b>fett</b>**</code> oder <code>***<b><em>fett kursiv</em></b>***</code> zu schreiben.<br><br>' +
      'Du kannst auch einen <code>[Link](https://tio.one)</code> setzen.'],
    ['#addTodoDuration', 'Optionale Dauer der Aufgabe, z.B. <code>10m</code> (zehn Minuten) oder <code>1h</code> (eine Stunde).'],
    ['#addTodoModal .date-time-input', 'Wann ist die Aufgabe fällig? Kann ein Tag, Woche, Monat oder Jahr sein.<br><br>Das gewählte Datum ist der Tag oder ein Tag in der Woche, usw.'],
    ['#addTodoModal .repeat-input', 'Optional eine Wiederholung der Aufgabe festlegen. Dazu links einen Haken setzen und ein Intervall auswählen, z.B. jeden Werktag ("alle ein Werktage") oder alle zwei Wochen.'],
    ['#addTodoRepeatPrio', 'Eine optionale Priorität der Aufgabe(nserie). Wird für die erste Einsortierung verwendet.<br><br>' +
      'Als Wert kann eine beliebige ganze Zahl verwendet werden. Aufgaben werden aufsteigend nach Priorität sortiert aufgelistet.'],
    ['#addTodoNotes', 'Beliebige Notizen zur Aufgabe. Du kannst hier auch Markdown verwenden. Z.B. auch Auflistungen mit <code>*</code>.'],
    ['#addTodoModal #resetTodo', 'Alle Eingaben in diesem Dialog auf den ursprünglichen Stand zurücksetzen.'],
    ['#addTodoModal .modal-footer .i18n-close', 'Eingabedialog schließen. Alle Eingaben werden verworfen.'],
    ['#addTodoSubmit', 'Neue Aufgabe abspeichern. '],
  ];
}

export function editModalTour_de() {
  return [
    ['#editTodoModal .modal-content', 'Der Änderungsdialog. Funktioniert genauso wie der Dialog für neue Aufgaben.<br><br>' +
      'Nur die Aktionsknöpfe unterscheiden sich.<br><br>' +
      'Achtung: Gehört die Aufgabe zu einer Serie, bearbeitest du erstmal nur die einzelne Aufgabe. Um die ganze Serie zu bearbeiten, gehe auf "Serie bearbeiten".'],
    ['#deleteTodo.i18n-delete', 'Löscht diese Aufgabe.'],
    ['#deleteTodoSeries.visible', 'Löscht eine Aufgabenserie. Du kannst wählen ob alle Wiederholungen oder nur die gewählte und alle folgenden Wiederholungen.'],
    ['#editTodoSeries.visible', 'Öffnet einen neuen Dialog, um die ganze Serie zu bearbeiten.'],
    ['#editTodoModal .modal-footer .i18n-close', 'Änderungsdialog schließen, alle Änderungen werden verworfen.'],
    ['#editTodoSubmit', 'Änderungen abspeichern.'],
  ];
}

export function menuTour_de() {
  return [
    ['#hamburger-menu', 'Dies ist das Menü von TIO. Hier findest du einige Funktionen, die nicht so häufig benötigt werden.'],
    ['#tio-version', 'Die aktuelle TIO Version.<br><br>Momentan ist TIO in der Beta-Phase und einige Dinge funktionieren noch nicht richtig.'],
    ['#add-todo-button', 'Neue Aufgabe am heutigen Tag zufügen. Hat die gleiche Funktion wie ein Klick in die heutige Karte.<br><br>Alternativ drücke die <b>+</b>-Taste.'],
    ['#sync-save-button', 'Aufgaben jetzt speichern.<br><br>Normalerweise brauchst du das nicht, da TIO automatisch sofort alles lokal speichert.<br><br>Wenn du TIO mit deiner Dropbox verknüpfst, wird auch regelmäßig der aktuelle Stand dort gespeichert. Wenn das mal nicht funktioniert, kannst du es hier erneut versuchen.'],
    ['#delete-all', 'Alle (wirklich alle!) Aufgabe löschen.<br><br>Mach das nur, wenn du genau weißt, was du tust.'],
    ['#preferences', 'Hier können verschiedene Einstellungen gemacht oder experimentelle Funktionen an- und ausgeschaltet werden.'],
    ['body:not(.dropbox-connected) #dropbox-login-button', 'TIO mit deiner Dropbox verknüpfen - als Backup und zur Synchronisierung zwischen mehreren Geräten.'],
    ['body.dropbox-connected #dropbox-logout-button', 'Dropbox-Verbindung wieder trennen.'],
    ['body.dropbox-connected #dropbox-file', 'Dropbox-Datei auswählen, in der alle TIO-Daten gespeichert werden.'],
    ['body:not(.google-connected) #google-login-button', 'TIO mit deinem Google-Konto verknüpfen und deine Kalender-Termine in TIO übernehmen.<br><br>' +
      'Das ist eine Einweg-Synchronisierung vom Kalender zu TIO. Änderungen im Kalender werden zu TIO übernommen. Wenn du in TIO einen Termin änderst, bleibt das in TIO.<br><br>' +
      'Nach einem Klick auf "Verbinden" öffnet sich sofort der Google-Login-Dialog.'
    ],
    ['body.google-connected #google-logout-button', 'Google-Verbindung wieder trennen.'],
    ['body.google-connected #google-calendar-list', 'Kalender zur Integration auswählen.'],
    ['#about-tio', 'Der Info-Dialog vom Start. Hier kann auch die Tour wieder aktiviert werden.'],
  ];
}

export function miniSyncTour_de() {
  return [
    ['#small-status', 'Wenn der Status-Knopf aus dem Bild scrollt, erscheint stattdessen diese kleine Version davon.'],
  ];
}

export function todoDetailsTour_de() {
  return [
    ['.todo-popover .popover-header', 'Dies sind die Details einer Aufgabe, hier oben steht der Titel.'],
    ['.todo-popover .popover-body', 'Hier stehen alle Notizen und weitere Informationen einer Aufgabe.'],
    ['.todo-popover .popover-header .bi-arrow-repeat', 'Eine Serienaufgabe wird durch einen runden Pfeil hier oben markiert.'],
    ['.todo-popover .popover-header', 'Dies sind die Details einer Aufgabe, hier oben steht der Titel.'],
    ['.todo-popover .popover-body .created-title', 'Wann wurde diese Aufgabe erzeugt?'],
    ['.todo-popover .popover-body .repeat-title', 'Nach welchem Schema wird eine Aufgabe wiederholt?'],
    ['.todo-popover .popover-body .original-created-title', 'Wann wurde die Serie erzeugt?'],
    ['.todo-popover .popover-footer .dropdown-toggle', 'Es gibt mehrere Aktionsknöpfe für eine Aufgabe. Dieser Pfeil daneben klappt noch weitere ähnliche Aktionen auf.'],
    ['.todo-popover .popover-footer .to-top', 'Die Aufgabe innerhalb ihrer Karte verschieben.'],
    ['.todo-popover .popover-footer .btn-group>.plus-1w', 'Die Aufgabe in die nächste Woche verschieben.'],
    ['.todo-popover .popover-footer .btn-group>.plus-1d', 'Die Aufgabe auf den nächsten Tag verschieben.'],
    ['.todo-popover .popover-footer .to-0', 'Die Aufgabe für heute einplanen.'],
    ['.todo-popover .popover-footer .clone', 'Eine 1:1 Kopie dieser Aufgabe erzeugen.'],
    ['.todo-popover .popover-footer .edit', 'Diese Aufgabe bearbeiten. Bei Serienaufgaben gibt es dort auch die Möglichkeit, die Serie zu bearbeiten.'],
    ['.todo-popover .popover-footer .delete', 'Diese (Einzel-)Aufgabe löschen.'],
  ];
}

import { startOfWeek, millisPerWeek, fmtDate, isThisWeek } from './dates.js';
import { showWeek, switchEssentialView } from './index.js';

export let start = startOfWeek(new Date());
export let current = fmtDate(start);

export function previousWeek() {
  changeWeekDate(- millisPerWeek);
  showWeek();
}
export function nextWeek() {
  changeWeekDate(millisPerWeek);
  showWeek();
}

export function thisWeek() {
  if (isThisWeek(current)) {
    switchEssentialView();
  }
  setDate(startOfWeek(new Date()));
  showWeek();
}


function changeWeekDate(diff) {
  setDate(startOfWeek(new Date(new Date(current).getTime() + diff)));
}


function setDate(dateObject) {
  start = dateObject;
  current = fmtDate(dateObject);
}
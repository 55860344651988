import Tagify from '@yaireo/tagify';
import '@yaireo/tagify/dist/tagify.css';

import { showWeek } from ".";
import { addChildEvent, addEvent, closeHamburger } from "./events";
import { listTodos } from "./items";
import { saveSettings, settings } from './settings';
import { escapeHtml } from 'markdown-it/lib/common/utils';
import { selectBox, updateRowCounter } from './html';
import { modal } from './modal';

export const selectedTags = [];
const UNTAGGED = '#untagged';
// internal prefix for automatic tags. Match without prefix and are not editable by the user.
const AUTOTAG_PREFIX = '#autotag-';

export function mostFrequentTagsButtons(tags) {
  let html = '';
  tags.forEach(tag => {
    const active = selectedTags.includes(tag);
    const activeString = active ? 'active' : '';
    const bgColor = getTagColor(tag);
    const textColor = getTextColorForBg(bgColor);
    const untaggedString = tag === UNTAGGED ? 'untagged' : '';
    const htmlTag = escapeHtml(tag);
    html += `<button type="button" style="color: ${textColor}; background-color: ${bgColor}; border-color: ${bgColor};" 
      class="btn btn-light tag-button ml-2 ${activeString} ${untaggedString}" data-tag="${htmlTag}">${htmlTag}</button>`;
  });
  return html;
}

function autoTagHtml(ctr = 0, name = "", field = "", operator = "", value = "") {
  const fieldSelect = selectBox('field', [{key:'title', name:'title'}, {key:'imported.type', name:'import'}], field);
  const operatorSelect = selectBox('operator', [{key:'=', name:'equals'}, {key:'*=', name:'contains'}], operator);
  const html = `
      <th scope="row" class="row-ctr">${ctr}</th>
      <td><input type="text" class="form-control form-control-sm" name="name" placeholder="Auto tag name" value="${name}" required></td>
      <td>${fieldSelect}</td>
      <td>${operatorSelect}</td>
      <td><input type="text" class="form-control form-control-sm" name="value" value="${value}" required></td>
      <td><button class="btn btn-danger delete" type="button">Delete</button></td>
  `;
  return html;
}

export function editAutoTags() {
  closeHamburger();
  let form = '';
  let ctr = 0;
  if (settings.autotags) {
    settings.autotags.forEach(autotag => {
      let field = autotag.field;
      if (!field) {
        field = 'title';
      }
      const operator = autotag.operator || '=';
      const value = autotag.value;
      const name = autotag.name || value;
      console.log(`${field} ${operator} ${value} => ${name}`);

      ctr++;
      form += '<tr>' + autoTagHtml(ctr, name, field, operator, value) + '</tr>';
    });
  }
  form = `<form>
  <table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Field</th>
      <th scope="col">Operator</th>
      <th scope="col">Value</th>
      <th scope="col">Delete?</th>
    </tr>
  </thead><tbody>${form}
    <tr class="actions"><td colspan="6" class="text-right"><button type="button" class="btn btn-outline-success add">Add</button></td></tr>
  </tbody></table></form>`;
  const modalEl = modal('auto-tags', 'Configure Auto Tags', 
  form, 'Save', 
    () => {return true;}, 'Cancel',
    () => {}).get(0);
    addChildEvent(modalEl, 'click', '.modal-submit', (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      const form = modalEl.querySelector('form');
      if (form.checkValidity() === false) {
        form.classList.add('was-validated');
      } else {
        const newAutoTags = [];
        form.querySelectorAll('tbody tr:not(.actions)').forEach(tr => {
          const autotag = {};
          autotag.name = tr.querySelector('[name="name"]').value;
          autotag.field = tr.querySelector('[name="field"]').value;
          autotag.operator = tr.querySelector('[name="operator"]').value;
          autotag.value = tr.querySelector('[name="value"]').value;
          newAutoTags.push(autotag);
        });
        settings.autotags = newAutoTags;
        saveSettings();
        window.location.reload();
      }
    });
    addChildEvent(modalEl, 'click', '.delete', (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      const delButton = evt.target;
      const table = delButton.closest('table');
      delButton.closest('tr').remove();
      updateRowCounter(table);
    });
    addChildEvent(modalEl, 'click', '.add', (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      const addButton = evt.target;
      const table = addButton.closest('table');
      const tbody = addButton.closest('tbody');
      const addRow = addButton.closest('tr');
      const newHtml = autoTagHtml();
      const tr = document.createElement('tr');
      tr.innerHTML = newHtml;
      tbody.insertBefore(tr, addRow);
      updateRowCounter(table);
    });
}

/**
 * Create a small button element in the color of the tag and the specified content.
 */
export function tagColorButton(tag, content) {
  const bgColor = getTagColor(tag);
  const textColor = getTextColorForBg(bgColor);
  const addBtn = document.createElement('button');
  addBtn.classList.add('btn', 'btn-sm', 'btn-light', 'bucket-done', 'ml-0', 'bucket-tag-counter');
  addBtn.innerText = escapeHtml(content);
  addBtn.title = escapeHtml(tag); 
  addBtn.setAttribute('style', `color: ${textColor}; background-color: ${bgColor}; border-color: ${bgColor};`);
  return addBtn;
}

export function getTextColorForBg(bgColor) {
  let textColor = 'black';
  if (isDark(bgColor)) {
    textColor = 'white';
  }
  return textColor;
}

export function applyAutotags(item) {
  if (!settings.autotags || settings.autotags.length === 0) {
    return;
  }
  settings.autotags.forEach(autotag => {
    let field = autotag.field;
    if (!field) {
      field = 'title';
    }
    const operator = autotag.operator || '=';
    const value = autotag.value;
    const name = autotag.name || value;
    console.log(`${field} ${operator} ${value} => ${name}`);
    let toCheck = '';
    if (field === 'imported.kind') {
      if (item.imported) {
        toCheck = item.imported.kind || '';
      }
    } else if (field === 'imported.type') {
      if (item.imported) {
        toCheck = item.imported.type || '';
      }
    } else if (field === 'title') {
      toCheck = item.title || '';
    }
    let match = false;
    if (toCheck !== '') {
      if (operator === '=') {
        match = value === toCheck;
      } else if (operator === '*=') {
        match = toCheck.indexOf(value) >= 0;
      }
    }
    if (match) {
      if (!item.tags) {
        item.tags = [];
      }
      const autotagName = AUTOTAG_PREFIX + name;
      if (!item.tags.includes(autotagName) && !item.tags.includes(name)) {
        console.log('Adding autotag ' + autotagName);
        item.tags.push(autotagName);
      }
    }
  });
}

export function tagMarker(item) {
  if (!item.tags) {
    return '';
  }
  let marker = '';
  let left = -11;
  const width = 3;
  item.tags.forEach(tag => {
    tag = removeAutoPrefix(tag);
    const color = getTagColor(tag);
    const thisMarker = `<div style="border-right: ${width}px solid ${color}; position: absolute; left:${left}px; top:25%; height:50%; z-index: 10;">&nbsp;</div>`;
    marker += thisMarker;
    left -= width;
  });
  return marker;
}

export function initTagSelection() {
  addEvent('click', '.tag-button', function (evt) {
    const tagButton = this;
    const tag = tagButton.dataset.tag;
    const index = selectedTags.indexOf(tag);
    if (index > -1) {
      selectedTags.splice(index, 1);
    } else if (evt.ctrlKey) {
      selectedTags.push(tag);
    } else {
      selectedTags.length = 0;
      selectedTags.push(tag);
    }
    showWeek();
  });
}

function countTag(countMap, tag) {
  if (!countMap.has(tag)) {
    countMap.set(tag, 0);
  }
  const before = countMap.get(tag);
  countMap.set(tag, before + 1);
}

export function countTags(countMap, item) {
  if (!item.tags || item.tags.length === 0) {
    countTag(countMap, UNTAGGED);
  } else {
    item.tags.forEach(tag => {
      tag = removeAutoPrefix(tag);
      countTag(countMap, tag);
    });  
  }
}
export function matchesSelectedTag(item) {
  if (selectedTags.length === 0) {
    return true;
  }
  if (!item.tags || item.tags.length === 0) {
    if (selectedTags.includes(UNTAGGED)) {
      return true;
    } else {
      return false;
    }
  }
  let match = false;
  item.tags.forEach(tag => {
    tag = removeAutoPrefix(tag);
    if (selectedTags.includes(tag)) {
      match = true;
    }
  });
  return match;
}

function incTagCounter(tagMap, tag) {
  if (tagMap.has(tag)) {
    const before = tagMap.get(tag);
    tagMap.set(tag, before + 1);
  } else {
    tagMap.set(tag, 1);
  }
}

export function isAutoTag(tag) {
  const autotag = tag.indexOf(AUTOTAG_PREFIX) === 0;
  return autotag;
}

export function removeAutoPrefix(tag) {
  if (tag.indexOf(AUTOTAG_PREFIX) === 0) {
    return tag.substring(AUTOTAG_PREFIX.length);
  }
  return tag;
}

export function countTodoTags(tagMap, todo) {
  if (todo.tags && todo.tags.length > 0) {
    todo.tags.forEach(tag => {
      tag = removeAutoPrefix(tag);
      incTagCounter(tagMap, tag);
    });
  } else {
    incTagCounter(tagMap, UNTAGGED);
  }
}

export function mostFrequentTagsOf(tagMap, max) {
  const values = Array.from(tagMap.entries());
  values.sort((e1, e2) => {
    const count1 = e1[1];
    const count2 = e2[1];
    return count2 - count1;
  });
  const frequent = [];
  values.slice(0, max).forEach(e => {
    frequent.push(e[0]);
  });
  selectedTags.forEach(tag => {
    if (!frequent.includes(tag)) {
      frequent.push(tag);
    }
  });
  return frequent; 
}

export function mostFrequentTags() {
  const nonDeleted = listTodos({deleted: true});
  const tagMap = new Map();
  nonDeleted.forEach(todo => {
    countTodoTags(tagMap, todo);
  });
  return mostFrequentTagsOf(tagMap, 5);
}

export function initTagify(selector) {
  const tagger = new Tagify(document.querySelector(selector), {
    dropdown: {
      enabled: 1,
      fuzzySearch: false
    },
    transformTag: transformTag,
    autoComplete: {
      tabKey: true
    },
    editTags: false
  });
  return tagger;
}

function transformTag( tagData ){
  tagData.color = getTagColor(tagData.value);
  tagData.style = "--tag-bg:" + tagData.color;
}

export function getTagColor(name){
  if (settings.tagcolors) {
    const predefined = settings.tagcolors[name.toLowerCase()];
    if (predefined) {
      return predefined;
    }
  } else {
    settings.tagcolors = {};
  }
  function rand(min, max) {
      return min + Math.random() * (max - min);
  }

  let h = rand(1, 360)|0,
      s = rand(40, 70)|0,
      l = rand(65, 72)|0;

  const newColor = hslToHex(h, s, l);
  settings.tagcolors[name.toLowerCase()] = newColor;
  saveSettings();
  return newColor;
}

function isDark(color) {
  // Check the format of the color, HEX or RGB?
  let r, g, b;
  if (color.match(/^rgb/)) {

    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } 
  else {

    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace( 
      color.length < 5 && /./g, '$&$&'
    )
             );

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  if (hsp>127.5) {
    return false;
  } else {
    return true;
  }
}

function hslToHex(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}
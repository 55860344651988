import {loadLastSyncTime, loadVersion, saveLastSyncTime, saveVersion, flagSyncClean, flagSyncDirty, isSyncClean, resetSync, overwriteTodos} from './storage.js';
import {refresh} from './index.js';
import {toast, toastNoHide} from './alerts.js';

import {Dropbox, DropboxAuth} from 'dropbox';
import jquery from 'jquery';
import { clean, dirty, disable, enable, setErrorState, setSelectOptions } from './html.js';
import { loadingModalHide, loadingModalShow, modal } from './modal.js';
import { closeHamburger } from './events.js';
import LZString from 'lz-string';
import { checkOnlineState } from './online.js';
import { saveSettings, settings } from './settings.js';

globalThis.tioRedirectUri = window.location.href.split('/').slice(0, 3).join('/');
let syncDirtyCounter = 0;

const fetchWithTimeout = (url, options) => new Promise((resolve, reject) => {
  let millis = 5000;
  if (url.endsWith('/files/upload') || url.endsWith('/files/download')) {
    millis = 10000;
  } else if (url.endsWith('/files/get_metadata')) {
    millis = 2000;
  }
  const timeout = setTimeout(() => reject(`timeout after ${millis}ms`), millis);
//  options.keepalive = true;
  return fetch(url, options)
    .then(response => {
      clearTimeout(timeout);

      if (response.status === 200) {
        return resolve(response);
      }

      return reject(response);
    }, rejectReason => {
      clearTimeout(timeout);

      return reject(rejectReason);
    });
});

const color = '#0d2481';
const storageDropboxFile = 'dropbox-file';

let syncFilename;
let syncFile;
setSyncFilename(localStorage.getItem(storageDropboxFile) || undefined);

const clientId = 's70zgmj4jha60qe';

let dbx = null;
let dbxAuth = new DropboxAuth({
  clientId: clientId,
});
let lastSyncTime = loadLastSyncTime();

let storeTimer = null;
let syncRunning = false;
const minSyncTimeout = 1000;
let syncTimeout = minSyncTimeout;

function setSyncFilename(name) {
  if (name) {
    syncFilename = name;
    syncFile = `/${syncFilename}.json`;
    localStorage.setItem(storageDropboxFile, name);
    updateSyncFilename();
  }
}

function dbxLoad(name) {
  setSyncFilename(name);
  resetSync();
  syncRunning = false;
  syncRead();
}

function dbxSaveAs(name) {
  setSyncFilename(name);
  forceSync();
}

function getSyncFilename() {
  return syncFilename;
}

function logoutDropbox() {
  dbx = null;
  localStorage.removeItem('dbx-token');
  initDropbox();
}

function getAccessTokenFromUrl() {
  const accessToken = new URLSearchParams(window.location.search).get('code');
  return accessToken;
}

function removeHash() { 
  history.replaceState("", document.title, globalThis.tioRedirectUri);
}

function isAuthenticated() {
  let token = localStorage.getItem('dbx-refresh-token');
  if (token) {
    doAuth();
    return true;
  }
  let code = getAccessTokenFromUrl();
  removeHash();
  if (code) {
    dbxAuth.setCodeVerifier(window.sessionStorage.getItem('codeVerifier'));
    dbxAuth.getAccessTokenFromCode(globalThis.tioRedirectUri, code)
    .then((response) => {
        localStorage.setItem('dbx-refresh-token', response.result.refresh_token);
        doAuth();
    })
    .catch((error) => {
      alert(error);
    });
    return true;
  }
  return false;
}

function doAuth() {
  let token = localStorage.getItem('dbx-refresh-token');
//  dbxAuth.setAccessToken(token);
  dbxAuth.setRefreshToken(token);
  dbx = new Dropbox({
      auth: dbxAuth, fetch: fetchWithTimeout
  });
  document.body.classList.add('dropbox-connected');
  const auth = document.getElementById('dropbox-authentication');
  const logoutButton = auth.querySelector('.btn-logout');
  logoutButton.addEventListener('click', logoutDropbox);
  getUsername();
  if (!syncFilename || syncFilename === "undefined") {
    chooseDropboxFileDialog();
  }
}

function updateUsername(name) {
  document
  .getElementById('dropbox-authentication')
  .querySelector('.username')
  .innerText = '(' + name + ')';
}

export function updateSyncFilename() {
  const el = document.getElementById('dropbox-file');
  if (el) {
    el.querySelector('.filename')
      .innerText = '(' + syncFilename + ')';
  }
}

function getUsername() {
  dbx.usersGetCurrentAccount()
  .then(function (response) {
    updateUsername(response.result.email);
  })
  .catch(function (error) {
    handleDropboxError(error);
    console.error("Dropbox: Get account failed.");
    console.log(error);
    alert("Dropbox: Get account failed: " + JSON.stringify(error));
  });
}

function handleDropboxError(error) {
  if (error.status === 401) {
    reauthenticate();
  }
}

export function initDropbox() {
  const auth = document.getElementById('dropbox-authentication');
  if (auth) {

    const loginButton = auth.querySelector('.btn-login');
    if (isAuthenticated()) {

    } else {
      document.body.classList.remove('dropbox-connected');
      loginButton.addEventListener('click', closeHamburger);
      if (checkOnlineState()) {
        dbxAuth
        .getAuthenticationUrl(globalThis.tioRedirectUri, undefined, 'code', 'offline', undefined, undefined, true)
        .then((authUrl) => {
          window.sessionStorage.clear();
          window.sessionStorage.setItem("codeVerifier", dbxAuth.codeVerifier);
          document.getElementById('connect-dropbox').href = authUrl;
        });
      }
    }
  }
}

function reauthenticate() {
  localStorage.removeItem('dbx-refresh-token');
  if (checkOnlineState()) {
    dbxAuth
    .getAuthenticationUrl(globalThis.tioRedirectUri, undefined, 'code', 'offline', undefined, undefined, true)
    .then((authUrl) => {
      window.sessionStorage.clear();
      window.sessionStorage.setItem("codeVerifier", dbxAuth.codeVerifier);
      window.location.href = authUrl;
    });
  }
}

export function scheduleSyncStore() {
  if (dbx === null) {
    return;
  }
  if (storeTimer) {
    clearTimeout(storeTimer);
    storeTimer = null;
  }
  storeTimer = setTimeout(function () {syncStore();}, syncTimeout);
}

function syncStore() {
  if (dbx === null) {
    return;
  }
  if (syncRunning) {
    console.log(syncRunning + " sync is running, rescheduling store.");
    storeTimer = null;
    scheduleSyncStore();
  } else {
    forceSync();
  }
}

export function forceNewSync() {
  if (syncRunning === 'store') {
    console.log('Store sync already running.');
    return new Promise();
  } else {
    return forceSync();
  }
}

export function forceSync() {
  if (dbx === null) {
    return new Promise();
  }
  if (!checkOnlineState()) {
    return new Promise();
  }
  syncRunning = 'store';
  const stringData = localStorage.getItem('todos');
  const compressedData = LZString.compressToEncodedURIComponent(stringData);
  const version = loadVersion() + 1;
  saveVersion(version);
  const jsonToSend = {
    compressedData: compressedData,
    version: version,
    tagcolors: settings.tagcolors,
    autotags: settings.autotags,
    repeattimers: settings.repeattimers,
  };
  const stringToSend = JSON.stringify(jsonToSend);
  const sizeInKb = Math.round(stringToSend.length / 1024);
  const tst = toastNoHide(`Storing data ({0} kB) to dropbox ({1}).`, color, sizeInKb, syncFilename);
  return dbx.filesUpload({path: syncFile, contents: stringToSend, mode: 'overwrite'})
  .then(function (response) {
    tst.toast('hide');
    const mod = response.result.server_modified;
    const modDate = new Date(mod);
    lastSyncTime = modDate;
    saveLastSyncTime(lastSyncTime);
    flagSyncClean();
    console.log("Sync successful.");
    syncRunning = false;
    syncTimeout = minSyncTimeout;
    vibrate();
    clean();
    syncDirtyCounter = 0;
  })
  .catch(function (err) {
    tst.toast('hide');
    console.log("Sync push failed.");
    console.error(err);
    incSyncTimeout();
    syncRunning = false;
    setErrorState();
    alert(JSON.stringify(err));
    toast("Storing to dropbox failed. Trying again in {0} seconds.", color, syncTimeout / 1000);
    scheduleSyncStore();
  });
}

function incSyncTimeout() {
  if (syncTimeout >= 30000) {
    syncTimeout = 60000;
  } else if (syncTimeout >= 10000) {
    syncTimeout = 30000;
  } else {
    syncTimeout = 10000;
  }
}

function listFiles(dbMod) {
  if (dbx === null) {
    throw "Dropbox not initialized.";
  }
  if (!checkOnlineState()) {
    return new Promise();
  }
  dbx
    .filesListFolder({path: ''})
    .then(response => {
      const names = [];
      response.result.entries.forEach(element => {
        const name = element.name;
        if (name.endsWith('.json')) {
          names.push(name.substring(0, name.length - 5));
        }
      });
      names.sort();
      setSelectOptions(dbMod, names, getSyncFilename());
    })
    .catch(response => {
      alert("Dropbox list failed: " + response);
    });
}
export function syncPeek() {
  if (dbx === null || !syncFilename) {
    return;
  }
  if (syncRunning) {
    console.log(syncRunning + " sync is running, suppressing peek.");
  } else {
    if (!checkOnlineState()) {
      return;
    }
    syncRunning = 'peek';
    dbx.filesGetMetadata({path: syncFile})
    .then(function (response) {
      const mod = response.result.server_modified;
      const modDate = new Date(mod);
      syncRunning = false;
      if (!lastSyncTime) {
        syncRead();
      } else if (modDate > lastSyncTime) {
        console.log(`Newer status on server: ${modDate} > ${lastSyncTime}`);
        syncRead();
      }
    })
    .catch(function (response) {
      console.error("Dropbox: Peek failed.");
      console.log(response);
      syncRunning = false;
    });
  }
}

let unclearDialogOpen = false;
export function syncRead() {
  if (dbx === null) {
    return;
  }
  if (syncRunning) {
    console.log(syncRunning + " sync is running, suppressing peek.");
  } else if (!isSyncClean()) {
    if (++syncDirtyCounter >= 1 && !unclearDialogOpen) {
      unclearDialogOpen = true;
      modal('unclear-sync', 'Unclear sync status', 
      '<p>Cannot reliably determine if local or remote data is newer.</p><p>Which do you want to use?</p>',
      'Local Data', () => {unclearDialogOpen = false; forceSync();}, 
      'Remote Data', () => {unclearDialogOpen = false; doSyncRead();});
    } else {
      console.log(`Not sync-reading due to inconsistent state.`);
    }
  } else if (!checkOnlineState()) {
    console.log(`Offline, not sync-reading.`);
  } else {
    doSyncRead();
  }
}

function doSyncRead() {
  syncDirtyCounter = 0;
  const tst = toastNoHide(`Reading data from dropbox ({0}).`, color, syncFilename);
  syncRunning = 'read';
  loadingModalShow('Synchronize data...');
  dbx.filesDownload({path: syncFile})
  .then(function (response) {
    tst.toast('hide');
    const mod = response.result.server_modified;
    const modDate = new Date(mod);
    lastSyncTime = modDate;
    const blob = response.result.fileBlob;
    const reader = new FileReader();
    reader.addEventListener('loadend', function () {
      let string = reader.result;
      let remoteVersion = 1;
      if (string.startsWith('LZW')) {
        string = LZString.decompressFromEncodedURIComponent(string.substring(3));
      } else if (string.startsWith('{')) {
        const json = JSON.parse(string);
        string = LZString.decompressFromEncodedURIComponent(json.compressedData);
        remoteVersion = json.version;
        if (json.tagcolors) {
          settings.tagcolors = json.tagcolors;
        }
        if (json.autotags) {
          settings.autotags = json.autotags;
        }
        if (json.repeattimers) {
          settings.repeattimers = json.repeattimers;
        }
        if (json.autotags || json.tagcolors || json.repeattimers) {
          saveSettings();
        }
      }
      const localVersion = loadVersion();
      if (remoteVersion > localVersion) {
        console.log(`Replacing local version ${localVersion} with remote version ${remoteVersion}.`);
        overwriteTodos(string);
        saveVersion(remoteVersion);
        toast(`Updated with remote todos.`);
      } else {
        console.log(`Not replacing local version ${localVersion} with remote version ${remoteVersion}.`);
        toast(`Not updating with outdated remote todos.`);
      }
      loadingModalHide();
      refresh();
    });
    reader.readAsText(blob);
    syncRunning = false;
  })
  .catch(function (response) {
    tst.toast('hide');
    console.error("Dropbox: Pull failed.");
    console.log(response);
    alert("Dropbox Sync pull failed: " + response);
    syncRunning = false;
    loadingModalHide();
  });
}

export function chooseDropboxFileDialog() {
  closeHamburger();
  const currentFile = getSyncFilename();
  const dm = modal('dropbox-file', 'Choose Dropbox File', 
  `<p><span>Choose the filename to store your TIO data in.</span><br/>
  <span>This is useful to maintain several todo lists.</span><br/>
  <span>(TIO files are stored below <code>/apps/tio.one</code>.)</span></p>
  
  <div class="form-group">
    <label for="select-dropbox-file">Choose an existing name: <small>(to load the file)</small></label>
    <div class="input-group">
      <select id="select-dropbox-file" class="select-dropbox-file custom-select disabled">
      </select>
      <span class="input-group-append">
        <span class="spinner-border spinner-border-sm ml-2 mt-2" role="status" aria-hidden="true"></span>
      </span>
    </div>
    <label for="enter-dropbox-file" class="mt-3">or enter a new one: <small>(to store the current todos)</small></label>
    <div class="input-group">
      <input class="form-control" id="enter-dropbox-file" type="text"/>
    </div>
  </div>`, 'Choose', evt => chooseDropboxFile(dm)).get(0);
  listFiles(dm);
  const sel = dm.querySelector('#select-dropbox-file');
  const text = dm.querySelector('#enter-dropbox-file');
  text.addEventListener('input', evt => {
    if (text.value.trim().length === 0) {
      enable(sel);
    } else {
      disable(sel);
    }
  });
}

function chooseDropboxFile(dbMod) {
  const sel = dbMod.querySelector('select');
  const fileFromList = sel.options[sel.selectedIndex].value;
  const customFile = dbMod.querySelector('#enter-dropbox-file').value;
  if (customFile.trim().length === 0) {
    dbxLoad(fileFromList);
  } else {
    dbxSaveAs(customFile);
  }
}


function vibrate() {
  if (!window) {
      return;
  }

  if (!window.navigator) {
      return;
  }

  if (!window.navigator.vibrate) {
      return;
  }

  window.navigator.vibrate(100);
}